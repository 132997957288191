const lt = {
  accept: "Supratau",
  next: "Tęsti",
  back: "Grįžti",
  skip: "Praleisti",
  language: "Kalba",
  loading: "Įkeliama...",

  // Errors
  wrongRepeat: "Nesutampa slaptažodžiai",
  requireField: "Šis laukas yra privalomas",
  errorFioField: "Patikrinkite, ar įvesti duomenys teisingi",
  pinMust4: "Turi būti 4 arba daugiau simbolių",
  pinMust6: "Turi būti 6 arba daugiau simbolių",
  must4: "Turėtų būti 4 skaitmenys",
  must6: "Turėtų būti 6 skaitmenys",
  must9: "Turėtų būti 9 skaitmenys",
  protectionPhoneMainError: "Atitinka pagrindinį numerį",
  mustBe: "Turėtų būti",
  mustBeEnd: "skaitmenys",
  unknownOperator: "Tik mobiliųjų telefonų numeriai",
  contactRepeats: "Naujas kontaktas atitinka anksčiau nurodytą",
  errorOldBrowserTitle: "Atnaujinkite naršyklę",
  errorOldBrowserText:
    "Šiuo metu naudojate senesnę naršyklės versiją. Norėdami tęsti, atnaujinkite naršyklę",
  enterEmailError: "Neteisingas el. pašto adresas",
  errorPageTitle502: "Tinklalapis laikinai nepasiekiamas",
  errorPageText502: "Mėginkite prisijungti vėliau",
  latNameError: "Užpildykite lotyniškais rašmenimis",
  wrongOtp: "Įvedėte neteisingą kodą. Liko {attempts} bandymų(as)",
  wrongPassword: "Įvedėte neteisingą slaptažodį. Liko {attempts} bandymų(as)",

  // Phone input
  inputCountry: "Šalis",
  inputCountryName: "Šalies pavadinimas",
  inputFound: "Paieškos rezultatai",
  inputNotFound: "Pagal užklausą <span>”{text}”</span> nieko neradome",

  // Email input
  emailInputLabel: "El. paštas",

  // Start
  startTitle: "Sveiki atvykę į Nova Postą!",
  enterTitle: "Įveskite telefono numerį",
  enterEmailTitle: "Įveskite el. pašto adresą",
  enterAcceptBtn: "Siųsti kodą",
  enterText:
    "Galite prisijungti arba sukurti paskyrą, jei esate naujas Nova Poste.",
  enterTextEmail: "Norėdami prisijungti, įveskite savo el. pašto adresą",
  enterOrRegistration: "Prisijungimas",
  shouldRegistration:
    "Prisijunkite ir gaukite prieigą prie išplėstinių nustatymų, galimybę užsisakyti papildomų paslaugų ir mokėti už jas internetu.",
  enterOr: "arba",
  enterPopupBtnEmail: "Prisijungti su el. paštu",
  enterPopupBtnPhone: "Prisijungti naudojant telefono numerį",

  // Social
  socialAppleBtn: "Tęsti su Apple",
  socialGoogleBtn: "Tęsti su Google",
  socialDeclinePopupTitle: "Autorizacija neįmanoma",
  socialDeclinePopupText:
    "Deja, nėra tokio paskyros. Prisijunkite naudodami savo telefono numerį, o po to pridėkite savo Google arba Apple paskyrą profilio nustatymuose.",
  socialDeclinePopupBtn: "A suprasta",

  // Enter Otp
  enterOtpTitle: "Įveskite SMS kodą",
  enterOtpTitleEmail: "Įveskite kodą iš el. pašto",
  enterOtpTextPhone:
    "Išsiuntėme patvirtinimo kodą numeriu <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span>",
  enterOtpTextEmail:
    "Elektroniniu paštu išsiuntėme patvirtinimo kodą <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span>",
  enterOtpSocialTitle:
    "Įveskite savo telefono numerį, kad prisijungtumėte su {process}",
  enterOtpSocialText:
    "Telefono numeris reikalingas pirmam prisijungimui su {process}, kad būtų rodomi jūsų siuntų duomenys. Vėlesniems prisijungimams telefono numerio įvesti nereikės.",
  enterOtpCalling: "Skambiname Jums",
  resendOtp: "Siųsti kodą dar kartą",
  resendOtpByCall: "Geriau paskambinkite",
  resendOtpAfter: "Siųsti dar kartą po 0:{time}",
  enterOtp502Title: "Deja, Jūsų nurodytu numeriu niekas neatsiliepia",
  enterOtp502Text: "Galbūt dėl prasto ryšio. Mėginkite dar kartą",

  // Enter password
  enterPasswordTitle: "Įveskite slaptažodį",
  enterPasswordText: "Tai slaptažodis, kurį nurodėte registracijos metu",
  enterPasswordLabel: "Slaptažodis",
  enterPasswordForgot: "Pamiršote slaptažodį?",
  passwordPopupTitle: "Neteisingas slaptažodis",
  passwordPopupText:
    "Jūs arba kas nors kitas kelis kartus įvedė neteisingą slaptažodį, todėl siekdami užtikrinti saugumą, užblokavome Jūsų paskyrą.",
  passwordPopupBtn1: "Grįžti į pradinį ekraną",

  // Create password
  createPasswordTitle: "Sukurti naują slaptažodį",
  createPasswordText:
    "Numeriui <br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Slaptažodis",
  createPasswordInput1Hint: "Turi būti 6 arba daugiau simbolių",
  createPasswordInput2Label: "Pakartokite slaptažodį",

  // Registration
  registrationTitle: "Sukurti paskyrą",
  registrationText:
    "Norėdami užsiregistruoti, įveskite savo telefono numerį ir sukurkite slaptažodį",
  registrationInput1Label: "Slaptažodis",
  registrationInput1Hint: "Turi būti 6 arba daugiau simbolių",
  registrationInput2Label: "Pakartokite slaptažodį",
  registrationPolicy: `Tęsdamas patvirtinu, kad perskaičiau {0} ir sutinku, kad mano asmens duomenys būtų tvarkomi`,
  registrationPolicyLink: `Privatumo politiką`,
  registrationPolicy1: `Pasirašydamas patvirtinu, kad perskaičiau ir supratau`,
  registrationPolicy2: `Privatumo politika`,
  registrationPolicy3: `ir sutinku, kad būtų tvarkomi mano asmens duomenys`,
  registration: "",

  // Full name
  fullNameTitle: "Sukurkite profilį",
  fullNameText: "Siuntų gavimui ir siuntimui.",
  fullNameBtn: "Registruotis",
  fullNameFirst: "Vardas",
  fullNameLast: "Pavardė",
  fullNameMiddle: "Tėvavardis",
  fullNameCheckbox: "Dokumentuose nėra tėvavardžio",

  // Create recovery
  createRecoveryTitle: "Apsaugokite savo paskyrą",
  createRecoveryText:
    "Užpildykite vieną iš papildomų kontaktų (savo el. pašto adresą arba papildomą telefono numerį), kad prireikus greičiau atnaujintumėte savo paskyrą.",
  createRecoveryHint:
    "Telefono numeris neturi sutapti su registracijos metu nurodytu telefono numeriu",

  // Choose recovery
  chooseRecoveryTitle: "Slaptažodžio atkūrimas",
  chooseRecoveryText: "Pasirinkite kontaktą ir laukite patvirtinimo kodo",
  chooseRecoveryGreyBtn: "Negaunu kodo",

  // Profile blocked
  profileBlockedTitle: "Laikinai apribota prieiga prie programėlės",
  profileBlockedText:
    "Išnaudojote visus bandymus įvesti kodą. Bandykite dar kartą po 10 minučių.",
  profileBlockedTimer:
    "Bandykite dar kartą po: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "Paskyra bus atrakinta už <span class='no-wrap text-black text-weight-medium'>{minutes} min</span>",
  profileBlockedBtn: "Supratau",

  // Recovery not found
  recoveryNotFoundTitle: "Registruokite naują paskyrą",
  recoveryNotFoundText1:
    "Jūs neįtraukėte jokių papildomų kontaktų, todėl negalime atkurti prieigos prie Jūsų paskyros <span class='no-wrap text-black text-weight-medium'>+{phone}</span>",
  recoveryNotFoundText2:
    "Užsiregistruokite dar kartą naudodami tą patį numerį. Informacija apie Jūsų siuntas išliks, tačiau sukauptų balų išsaugoti negalėsime",
  recoveryNotFoundBtn: "Registruotis",
  recoveryNotNowBtn: "Ne dabar",

  recoveryNotFoundText1Another:
    "Jūs neturite prieigos prie savo kontaktų, todėl negalime atkurti prieigos prie Jūsų paskyros <span class='no-wrap text-black text-weight-medium'>+{phone}</span>.",

  // Password changed
  passwordChangedTitle: "Slaptažodis pakeistas",
  passwordChangedText1:
    "Slaptažodį naudokite tik novapost.com svetainėje ir programėlėje.",
  passwordChangedText2:
    "Niekam neatskleiskite savo slaptažodžio. Mūsų darbuotojai niekada jo neprašo",
  passwordChangedBtn: "Supratau",

  // Error limit 10
  errorLimit10PageTitle: "Jūsų numeris laikinai užblokuotas",
  errorLimit10PageTextWithNumber:
    "Mums nepavyko patvirtinti Jūsų numerio naudojant kodą arba Jūsų žinutės nebuvo pristatytos dėl nestabilaus ryšio.",
  errorLimit10PageTimer:
    "Prašome pamėginti dar kartą už <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  errorLimit10PageBtn: "Grįžti į pradinį ekraną",

  // Error Reg Limit
  errorRegLimitTitle: "Pamėginkite vėliau",
  errorRegLimitText:
    "Registracijų vienu numeriu skaičius yra ribotas. Prašome pamėginti registruotis po 4 valandų",
  errorRegLimitBtn: "Grįžti į pradinį ekraną",

  // Error unforeseen
  errorUnforeseenTitle: "Įvyko klaida",
  errorUnforeseenText: "Pamėginkite prisijungti dar kartą",
  errorUnforeseenBtn: "Supratau",

  // Bot dialog
  botDialogTitle: "Įvyko klaida",
  botDialogText: "Pabandykite dar kartą prisijungti",
  botDialogBtn: "Supratau",

  // Email for login not found
  emailNotFoundTitile: "Deja nepavyko rasti profilio šiam el. pašto adresui",
  emailNotFoundText: "Autentifikuotis reikia naudojant telefono numerį",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "Ši galimybė yra prieinama tik juridinių asmenų atstovams",
  loginByEmailDeclinedText: "Autentifikuotis reikia naudojant telefono numerį",
};

export default lt;
