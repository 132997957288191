const tr = {
  accept: "Anladım",
  next: "Devam et",
  back: "Geri",
  skip: "Geç",
  language: "Dil",
  loading: "Yükleniyor...",

  // Errors
  wrongRepeat: "Şifreler uyuşmuyor",
  requireField: "Bu zorunlu bir alan",
  errorFioField: "Girilen verilerin doğruluğunu kontrol edin",
  pinMust4: "4 veya daha fazla karakter içermelidir",
  pinMust6: "6 veya daha fazla karakter içermelidir",
  must4: "4 rakam olmalı",
  must6: "6 rakam olmalı",
  must9: "9 rakam olmalı",
  protectionPhoneMainError: "Ana numarayla eşleşiyor",
  mustBe: "Rakamlar ",
  mustBeEnd: " olmalı",
  unknownOperator: "Yalnızca cep telefonu numaraları",
  contactRepeats:
    "Yeni iletişim bilgileri daha önce belirtilen bilgi ile eşleşiyor",
  errorOldBrowserTitle: "Tarayıcınızı güncelleyin",
  errorOldBrowserText:
    "Şu anda tarayıcının eski bir sürümünü kullanıyorsunuz. Devam etmek için lütfen tarayıcınızı güncelleyin",
  enterEmailError: "Geçersiz e-posta adresi",
  errorPageTitle502: "Hizmet geçici olarak kullanılamıyor",
  errorPageText502: "Daha sonra bağlanmayı deneyin",
  latNameError: "Lütfen Latin harfleriyle doldurunuz",
  wrongOtp: "Yanlış kodu girdiniz. {attempts} deneme kaldı",
  wrongPassword: "Yanlış şifre girdiniz. {attempts} deneme kaldı",

  // Phone input
  inputCountry: "Ülke",
  inputCountryName: "Ülke adı",
  inputFound: "Arama sonuçları",
  inputNotFound: "<span>”{text}”</span> talebi için hiçbir şey bulamadık",

  // Email input
  emailInputLabel: "E-posta",

  // Start
  startTitle: "Nova Post'a Hoşgeldiniz!",
  enterTitle: "Telefon numarasını girin",
  enterEmailTitle: "E-posta adresini girin",
  enterAcceptBtn: "Kodu gönder",
  enterText:
    "Nova Post'a yeniyseniz, giriş yapabilir veya hesap oluşturabilirsiniz.",
  enterTextEmail: "Giriş yapmak için e-posta adresinizi girin",
  enterOrRegistration: "Giriş",
  shouldRegistration:
    "Giriş yapın ve gelişmiş ayarlara, ek hizmetler siparişine ve çevrimiçi olarak ödemeye erişin.",
  enterOr: "veya",
  enterPopupBtnEmail: "E-posta ile giriş yap",
  enterPopupBtnPhone: "Telefon numarasıyla giriş yapın",

  // Enter Otp
  enterOtpTitle: "SMS'ten gelen kodu girin",
  enterOtpTitleEmail: "E-postadan gelen kodu girin",
  enterOtpTextPhone:
    "Onay kodunu <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span> numarasına gönderdik",
  enterOtpTextEmail:
    "Onay kodunu <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span> e-postasına gönderdik",
  enterOtpSocialTitle:
    "{process} ile giriş yapmak için telefon numaranızı girin",
  enterOtpSocialText:
    "Telefon numarası, {process} ile ilk giriş için gereklidir, böylece paketlerinizle ilgili bilgileri görüntüleyebilirsiniz. Gelecekteki girişler için telefon numaranızı girmeniz gerekmeyecek.",
  enterOtpCalling: "Sizi arıyoruz",
  resendOtp: "Kodu tekrar gönder",
  resendOtpByCall: "Arayın lütfen",
  resendOtpAfter: "0 {time} sonra tekrar gönder",
  enterOtp502Title: "Maalesef, belirtilen numara cevap vermiyor",
  enterOtp502Text: "Zayıf bağlantı yüzünden olabilir. Tekrar deneyin",

  // Enter password
  enterPasswordTitle: "Şifreyi girin",
  enterPasswordText: "Bu şifreyi kayıt sırasında belirttiniz",
  enterPasswordLabel: "Şifre",
  enterPasswordForgot: "Şifrenizi mi unuttunuz?",
  passwordPopupTitle: "Yanlış şifre",
  passwordPopupText:
    "Siz veya başka bir kişi birkaç kez yanlış şifre girdi. Güvenliğiniz için profilinizi engelledik.",
  passwordPopupBtn1: "Ana ekrana dön",

  // Create password
  createPasswordTitle: "Yeni bir şifre oluşturun",
  createPasswordText:
    "<span class='no-wrap text-black white'>+{phone}</span><br /> numarası için",
  createPasswordInput1Label: "Şifre",
  createPasswordInput1Hint: "6 veya daha fazla karakter içermelidir",
  createPasswordInput2Label: "Şifrenizi bir daha girin",

  // Registration
  registrationTitle: "Profil oluştur",
  registrationText:
    "Kaydolmak için telefon numaranızı girin ve şifre oluşturun",
  registrationInput1Label: "Şifre",
  registrationInput1Hint: "En az 6 karakter içermelidir",
  registrationInput2Label: "Şifrenizi bir daha girin",
  registrationPolicy: `Devam ederken {0} okuduğumu ve kişisel verilerimin işlenmesine onay verdiğimi onaylıyorum`,
  registrationPolicyLink: `Gizlilik Politikasını`,
  registrationPolicy1: `Devam ederken`,
  registrationPolicy2: `Gizlilik Politikasını okuduğumu`,
  registrationPolicy3: `ve kişisel verilerimin işlenmesine onay verdiğimi onaylıyorum`,
  registration: "",

  // Full name
  fullNameTitle: "Profil oluşturun",
  fullNameText: "Paket almak ve göndermek için.",
  fullNameBtn: "Kayıt ol",
  fullNameFirst: "Ad",
  fullNameLast: "Soyad",
  fullNameMiddle: "Baba adı",
  fullNameCheckbox: "Belgelerde baba adı yok",

  // Create recovery
  createRecoveryTitle: "Profilinizi koruyun",
  createRecoveryText:
    "Gerektiğinde profilinizi daha hızlı geri yüklemek için ek iletişim yöntemi için bilgi (e-posta adresiniz veya ek numaranız) girin.",
  createRecoveryHint:
    "Telefon numarası kayıt sırasında girilen numarayla eşleşmemelidir",

  // Choose recovery
  chooseRecoveryTitle: "Şifre yenile",
  chooseRecoveryText: "Bir iletişim yöntemi seçin ve onay kodunu bekleyin",
  chooseRecoveryGreyBtn: "Kod alamıyorum",

  // Profile blocked
  profileBlockedTitle: "Uygulamaya erişim geçici olarak kısıtlandı",
  profileBlockedText:
    "Kodu girme girişimlerinin tümünü kullandınız. Lütfen 10 dakika sonra tekrar deneyin.",
  profileBlockedTimer:
    "Tekrar deneyin: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "Profil  <span class='no-wrap text-black text-weight-medium'>{minutes} dakika sonra açılacak</span>",
  profileBlockedBtn: "Anladım",

  // Recovery not found
  recoveryNotFoundTitle: "Yeni bir profil kaydet",
  recoveryNotFoundText1:
    "Ek iletişim yöntemi için bilgi vermediğiniz için <span class='no-wrap text-black text-weight-medium'>+{phone}</span> numarası ile kayıtlı profile erişiminizi geri yükleyemiyoruz.",
  recoveryNotFoundText2:
    "Lütfen aynı numarayla tekrar kaydolun. Paketlerinize ait bilgiler kalacak, ancak biriken puanları kaydedemeyeceğiz",
  recoveryNotFoundBtn: "Kaydol",
  recoveryNotNowBtn: "Şimdi değil",

  recoveryNotFoundText1Another:
    "İletişim yöntemlerine erişiminiz yok, bu nedenle  <span class='no-wrap text-black text-weight-medium'>+{phone}</span> numarası ile kayıtlı profile erişiminizi geri yükleyemiyoruz.",

  // Password changed
  passwordChangedTitle: "Şifre değiştirildi",
  passwordChangedText1:
    "Şifreyi yalnızca novaposhta.ua web sitesinde ve uygulamada kullanın.",
  passwordChangedText2:
    "Şifrenizi kimseyle paylaşmayın. Çalışanlarımız bunu asla sormaz",
  passwordChangedBtn: "Anladım",

  // Error limit 10
  errorLimit10PageTitle: "Numaranız geçici olarak engellendi",
  errorLimit10PageTextWithNumber:
    "Numaranızı kodla doğrulayamadık veya zayıf bağlantı nedeniyle mesajlarınız teslim edilemedi.",
  errorLimit10PageTimer:
    "Lütfen   <span class='no-wrap text-black text-weight-medium'>{time}</span> sonra tekrar deneyin",
  errorLimit10PageBtn: "Ana ekrana dön",

  // Error Reg Limit
  errorRegLimitTitle: "Daha sonra tekrar deneyin",
  errorRegLimitText:
    "Tek numara için kayıt sayısı sınırlıdır. Lütfen 4 saat sonra giriş yapmayı deneyin",
  errorRegLimitBtn: "Ana ekrana dön",

  // Error unforeseen
  errorUnforeseenTitle: "Bir hata oluştu",
  errorUnforeseenText: "Giriş yapmayı tekrar deneyin",
  errorUnforeseenBtn: "Anladım",

  // Bot dialog
  botDialogTitle: "Bir hata oluştu",
  botDialogText: "Giriş yapmayı tekrar deneyin",
  botDialogBtn: "Anladım",

  // Email for login not found
  emailNotFoundTitile: "Maalesef bu e-posta adresi için profil bulunamadı",
  emailNotFoundText: "Telefon numarasıyla kimlik doğrulaması gerekmektedir",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "Bu seçenek yalnızca tüzel kişilik temsilcileri için geçerlidir",
  loginByEmailDeclinedText:
    "Telefon numarasıyla kimlik doğrulaması gerekmektedir",
};

export default tr;
