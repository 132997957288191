const uk = {
  accept: "To je jasné",
  next: "Pokračovať",
  back: "Späť",
  skip: "Vynechať",
  language: "Jazyk",
  loading: "Načítava sa...",

  // Errors
  wrongRepeat: "Heslá sa nezhodujú",
  requireField: "Toto pole je povinné",
  errorFioField: "Skontrolujte, či sú zadané údaje správne",
  pinMust4: "Musí mať najmenej 4 znaky",
  pinMust6: "Musí mať najmenej 6 znakov",
  must4: "Musia byť 4 číslice",
  must6: "Musí byť 6 číslic",
  must9: "Musí byť 9 číslic",
  protectionPhoneMainError: "Zhoduje sa s primárnym číslom",
  mustBe: "Musí byť",
  mustBeEnd: " číslic",
  unknownOperator: "Len mobilné čísla",
  contactRepeats: "Nový kontakt sa zhoduje s už uvedeným kontaktom",
  errorOldBrowserTitle: "Obnovte prehliadač",
  errorOldBrowserText:
    "Momentálne používate staršiu verziu prehliadača. Ak chcete pokračovať, obnovte prosím prehliadač",
  enterEmailError: "Nesprávna e-mailová adresa.",
  errorPageTitle502: "Služba je dočasne nedostupná",
  errorPageText502: "Skúste sa pripojiť o niečo neskôr",
  latNameError: "Prosím, vyplňte latinkou",
  wrongOtp: "Zadali ste nesprávny kód. Zostáva  {attempts} pokus(y)",
  wrongPassword: "Zadali ste nesprávne heslo. Zostal {attempts} pokus(y)",

  // Phone input
  inputCountry: "Krajina",
  inputCountryName: "Názov krajiny",
  inputFound: "Výsledky vyhľadávania",
  inputNotFound: "Pre túto požiadavku sme nič nenašli <span>”{text}”</span>",

  // Email input
  emailInputLabel: "Email",

  // Start
  startTitle: "Vitajte v Nova Post!",
  enterTitle: "Zadajte telefónne číslo",
  enterEmailTitle: "Zadajte e-mailovú adresu",
  enterAcceptBtn: "Odoslať kód",
  enterText:
    "Môžete sa prihlásiť alebo vytvoriť účet, ak ste v Nova Post nový.",
  enterTextEmail: "Na prihlásenie zadajte svoju e-mailovú adresu",
  enterOrRegistration: "Prihlásenie",
  shouldRegistration:
    "Prihláste sa a získajte prístup k rozšíreným nastaveniam, objednajte si ďalšie služby a zaplaťte za ne online.",
  enterOr: "alebo",
  enterPopupBtnEmail: "Prihlásiť sa e-mailom",
  enterPopupBtnPhone: "Prihlásiť sa telefónnym číslom",

  // Social
  socialAppleBtn: "Pokračovať s Apple",
  socialGoogleBtn: "Pokračovať s Google",
  socialDeclinePopupTitle: "Kontu turintiems prisijungti",
  socialDeclinePopupText:
    "Deja, tokio paskyros nėra. Prisijunkite naudodami savo telefono numerį ir tuomet pridėkite savu Google arba Apple paskyrą profilio nustatymuose.",
  socialDeclinePopupBtn: "Aptarnaujama",

  // Enter Otp
  enterOtpTitle: "Zadajte kód z SMS",
  enterOtpTitleEmail: "Zadajte kód z e-mailu",
  enterOtpTextPhone:
    "Odoslali sme potvrdzovací kód na číslo <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span>",
  enterOtpTextEmail:
    "Odoslali sme potvrdzovací kód na e-mail <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span>",
  enterOtpSocialTitle:
    "Zadajte svoje telefónne číslo, aby ste sa prihlásili pomocou {process}",
  enterOtpSocialText:
    "Telefónne číslo je potrebné pri prvom prihlásení pomocou {process}, aby sa zobrazili informácie o vašich balíkoch. Pri ďalších prihláseniach už nebude potrebné zadávať telefónne číslo.",
  enterOtpCalling: "Voláme vám",
  resendOtp: "Odoslať kód znova",
  resendOtpByCall: "Radšej zavolajte",
  resendOtpAfter: "Odoslať znova cez 0:{time}",
  enterOtp502Title: "Žiaľ, uvedené číslo nezodpovedá",
  enterOtp502Text: "Možno kvôli zlému spojeniu. Skúste to znova.",

  // Enter password
  enterPasswordTitle: "Zadajte heslo",
  enterPasswordText: "Toto heslo ste zadali pri registrácii",
  enterPasswordLabel: "Heslo",
  enterPasswordForgot: "Zabudli ste heslo?",
  passwordPopupTitle: "Nesprávne heslo.",
  passwordPopupText:
    "Vy alebo niekto iný zadal niekoľkokrát nesprávne heslo. Z dôvodu ochrany sme zablokovali profil.",
  passwordPopupBtn1: "Vrátiť sa na domovskú obrazovku",

  // Create password
  createPasswordTitle: "Vytvorte nové heslo",
  createPasswordText:
    "Pre číslo <br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Heslo",
  createPasswordInput1Hint: "Musí mať najmenej 6 znakov",
  createPasswordInput2Label: "Zopakujte heslo",

  // Registration
  registrationTitle: "Vytvoriť profil",
  registrationText:
    "Ak sa chcete zaregistrovať, zadajte svoje telefónne číslo a vytvorte si heslo.",
  registrationInput1Label: "Heslo",
  registrationInput1Hint: "Musí mať minimálne 6 znakov.",
  registrationInput2Label: "Zopakujte heslo",
  registrationPolicy: `Pokračovaním potvrdzujem, že som si prečítal {0} a súhlasím so spracovaním mojich osobných údajov,`,
  registrationPolicyLink: `Pravidlá ochrany osobných údajov`,
  registrationPolicy1: `Pokračovaním potvrdzujem, že som oboznámený s `,
  registrationPolicy2: `Zásadami ochrany osobných údajov`,
  registrationPolicy3: `a súhlasím so spracovaním svojich osobných údajov`,
  registration: "",

  // Full name
  fullNameTitle: "Vytvorte profil",
  fullNameText: "Na prijímanie a odosielanie balíkov.",
  fullNameBtn: "Registrovať sa",
  fullNameFirst: "Meno",
  fullNameLast: "Priezvisko",
  fullNameMiddle: "Otcovské meno",
  fullNameCheckbox: "V dokumentoch nie je otcovské meno",

  // Create recovery
  createRecoveryTitle: "Ochráňte svoj profil",
  createRecoveryText:
    "Vyplňte jeden z ďalších kontaktov (svoj e-mail alebo iné číslo), aby ste v prípade potreby mohli rýchlejšie obnoviť svoj profil.",
  createRecoveryHint:
    "Telefónne číslo nesmie byť rovnaké ako telefónne číslo zadané pri registrácii",

  // Choose recovery
  chooseRecoveryTitle: "Obnovenie hesla",
  chooseRecoveryText: "Vyberte kontakt a počkajte na potvrdenie kódu",
  chooseRecoveryGreyBtn: "Nemôžem získať kód",

  // Profile blocked
  profileBlockedTitle: "Prístup k aplikácii je dočasne obmedzený",
  profileBlockedText:
    "Vyčerpali ste všetky pokusy o zadanie kódu. Skúste to znova o 10 minút.",
  profileBlockedTimer:
    "Skúste to znova o: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "Profil sa odblokuje o: <span class='no-wrap text-black text-weight-medium'>{minutes} хв</span>",
  profileBlockedBtn: "To je jasné",

  // Recovery not found
  recoveryNotFoundTitle: "Zaregistrujte si nový profil",
  recoveryNotFoundText1:
    "Nezadali ste žiadne ďalšie kontakty, takže nemôžeme obnoviť prístup k vášmu profilu cez telefónne číslo: <span class='no-wrap text-black text-weight-medium'>+{phone}</span>",
  recoveryNotFoundText2:
    "Zaregistrujte sa znova na tom istom čísle. Informácie o vašej zásielke zostanú uložené, ale nebudeme môcť zachovať vaše nazbierané body",
  recoveryNotFoundBtn: "Zaregistrovať sa",
  recoveryNotNowBtn: "Teraz nie",

  recoveryNotFoundText1Another:
    "Nemáte prístup k svojim kontaktom, takže nemôžeme obnoviť prístup k vášmu profilu na čísle: <span class='no-wrap text-black text-weight-medium'>+{phone}</span>.",

  // Password changed
  passwordChangedTitle: "Heslo bolo zmenené",
  passwordChangedText1:
    "Heslo používajte len na webovej stránke novapost.com a v aplikácii.",
  passwordChangedText2:
    "Nikomu nedávajte svoje heslo. Naši zamestnanci si ho nikdy nepýtajú",
  passwordChangedBtn: "To je jasné",

  // Error limit 10
  errorLimit10PageTitle: "Vaše číslo bolo dočasne zablokované",
  errorLimit10PageTextWithNumber:
    "Nepodarilo sa nám overiť vaše číslo podľa kódu alebo z dôvodu nestabilného pripojenia neboli vaše správy doručené.",
  errorLimit10PageTimer:
    "Prosím, skúste to znova o: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  errorLimit10PageBtn: "Vrátiť sa na domovskú obrazovku",

  // Error Reg Limit
  errorRegLimitTitle: "Skúste to neskôr",
  errorRegLimitText:
    "Počet registrácií na jedno číslo je obmedzený. Skúste sa zaregistrovať o 4 hodiny",
  errorRegLimitBtn: "Vrátiť sa na domovskú obrazovku",

  // Error unforeseen
  errorUnforeseenTitle: "Vyskytla sa chyba",
  errorUnforeseenText: "Skúste sa znova prihlásiť",
  errorUnforeseenBtn: "To je jasné",

  // Bot dialog
  botDialogTitle: "Vyskytla sa chyba",
  botDialogText: "Skúste sa znova prihlásiť",
  botDialogBtn: "To je jasné",

  // Email for login not found
  emailNotFoundTitile:
    "Bohužiaľ, pre túto e-mailovú adresu sa nepodarilo nájsť profil",
  emailNotFoundText:
    "Je potrebná autorizácia prostredníctvom telefónneho čísla",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "Táto možnosť je k dispozícii iba pre zástupcov právnických osôb",
  loginByEmailDeclinedText:
    "Je potrebná autorizácia prostredníctvom telefónneho čísla",
};

export default uk;
