const it = {
  accept: "Chiaro",
  next: "Continua",
  back: "Indietro",
  skip: "Salta",
  language: "Lingua",
  loading: "Caricamento...",

  // Errors
  wrongRepeat: "Le password non coincidono",
  requireField: "Campo obbligatorio",
  errorFioField: "Verifica la correttezza dei dati inseriti",
  pinMust4: "Deve contenere almeno 4 caratteri",
  pinMust6: "Deve contenere almeno 6 caratteri",
  must4: "Deve contenere 4 cifre",
  must6: "Deve contenere 6 cifre",
  must9: "Deve contenere 9 cifre",
  protectionPhoneMainError: "Coincide con il numero principale",
  mustBe: "Deve contenere ",
  mustBeEnd: " cifre",
  unknownOperator: "Solo numeri mobili",
  contactRepeats:
    "Il contatto nuovo coincide con quello indicato in precedenza",
  errorOldBrowserTitle: "Aggiorna browser",
  errorOldBrowserText:
    "Stai utilizzando la versione vecchia del browser. Si prega di aggiornare il browser per continuare",
  enterEmailError: "Indirizzo di posta elettronica errato",
  errorPageTitle502: "Il servizio non è temporaneamente disponibile",
  errorPageText502: "Prova a connetterti un po' più tardi",
  latNameError: "Si prega di usare caratteri latini",
  wrongOtp:
    "Hai inserito un codice errato. Sono (È) rimasti (o) {attempts} tentativi(o)",
  wrongPassword:
    "Hai inserito la password errata. Sono (È) rimasti (o) {attempts} tentativi(o)",

  // Phone input
  inputCountry: "Stato",
  inputCountryName: "Nome dello Stato",
  inputFound: "Risultati della ricerca",
  inputNotFound:
    "Nessun risultato trovato relativo alla tua richiesta <span>”{text}”</span>",

  // Email input
  emailInputLabel: "Email",

  // Start
  startTitle: "Benvenuto in Nova Post!",
  enterTitle: "Inserisci il numero di telefono",
  enterEmailTitle: "Inserisci l'indirizzo email",
  enterAcceptBtn: "Invia il codice",
  enterText: "Puoi accedere o creare un account se sei nuovo su Nova Post.",
  enterTextEmail: "Per accedere, inserisci la tua email",
  enterOrRegistration: "Accesso",
  shouldRegistration:
    "Entra per avere accesso alle impostazioni avanzate, richiedere servizi aggiuntivi e pagarli online",
  enterOr: "o",
  enterPopupBtnEmail: "Accedi con email",
  enterPopupBtnPhone: "Accedi con numero di telefono",

  // Social
  socialAppleBtn: "Continua con Apple",
  socialGoogleBtn: "Continua con Google",
  socialDeclinePopupTitle: "Impossibile autenticarsi",
  socialDeclinePopupText:
    "Purtroppo, non esiste un account del genere. Autenticati usando il tuo numero di telefono e poi aggiungi il tuo account Google o Apple nelle impostazioni del profilo.",
  socialDeclinePopupBtn: "Capito",

  // Enter Otp
  enterOtpTitle: "Inserisci il codice dall'SMS",
  enterOtpTitleEmail: "Inserisci il codice dall'e-mail",
  enterOtpTextPhone:
    "Abbiamo inviato il codice di conferma al numero <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span>",
  enterOtpTextEmail:
    "Abbiamo inviato il codice di conferma all'indirizzo di posta elettronica <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span>",
  enterOtpSocialTitle:
    "Inserisci il tuo numero di telefono per accedere con {process}",
  enterOtpSocialText:
    "Il numero di telefono è richiesto per il primo accesso con {process} per visualizzare le informazioni sui tuoi pacchi. Per i futuri accessi, non sarà necessario inserire il numero di telefono.",
  enterOtpCalling: "Ti stiamo telefonando",
  resendOtp: "Invia nuovamente il codice",
  resendOtpByCall: "Meglio telefonare",
  resendOtpAfter: "Inviare ancora una volta tra 0:{time}",
  enterOtp502Title: "Purtroppo il numero indicato non risponde",
  enterOtp502Text:
    "Probabilmente ci sono dei problemi di connessione di rete. Riprova",

  // Enter password
  enterPasswordTitle: "Inserisci la password",
  enterPasswordText: "Questa password l'hai indicata durante la registrazione",
  enterPasswordLabel: "Password",
  enterPasswordForgot: "Ha dimenticato la password?",
  passwordPopupTitle: "Password errata",
  passwordPopupText:
    "Tu o qualcun altro ha inserito una password errata. Per sicurezza abbiamo bloccato il profilo. ",
  passwordPopupBtn1: "Torna alla pagina iniziale",

  // Create password
  createPasswordTitle: "Creare una nuova password",
  createPasswordText:
    "Per il numero <br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Password",
  createPasswordInput1Hint: "Deve contenere almeno 6 caratteri",
  createPasswordInput2Label: "Ripeti la password",

  // Registration
  registrationTitle: "Crea il profilo",
  registrationText:
    "Per la registrazione inserisci il tuo numero di telefono e crea una password",
  registrationInput1Label: "Password",
  registrationInput1Hint: "Deve contenere almeno 6 caratteri",
  registrationInput2Label: "Ripeti la password",
  registrationPolicy: `Continuando, confermo di aver preso visione {0} e acconsento al trattamento dei miei dati personali`,
  registrationPolicyLink: `dell'Informativa sulla privacy`,
  registrationPolicy1: `Continuando, confermo di aver preso visione`,
  registrationPolicy2: `dell'Informativa sulla Privacy`,
  registrationPolicy3: `e acconsento al trattamento dei miei dati personali`,
  registration: "",

  // Full name
  fullNameTitle: "Crea un profilo",
  fullNameText: "Per ricevere e inviare pacchi.",
  fullNameBtn: "Registrati",
  fullNameFirst: "Nome",
  fullNameLast: "Cognome",
  fullNameMiddle: "Patronimico",
  fullNameCheckbox: "Non è indicato il patronimico nei documenti",

  // Create recovery
  createRecoveryTitle: "Proteggi il tuo profilo",
  createRecoveryText:
    "Indica uno dei contatti aggiuntivi (la tua email o un numero aggiuntivo) per poter velocemente ripristinare il tuo profilo se necessario.",
  createRecoveryHint:
    "Il numero di telefono non deve coincidere con quello indicato durante la registrazione",

  // Choose recovery
  chooseRecoveryTitle: "Ripristino della password",
  chooseRecoveryText: "Scegli il contatto e aspetta il codice di conferma",
  chooseRecoveryGreyBtn: "Non riesco a ricevere il codice",

  // Profile blocked
  profileBlockedTitle: "Accesso all'app temporaneamente limitato",
  profileBlockedText:
    "Hai esaurito tutti i tentativi per inserire il codice. Riprova tra 10 minuti.",
  profileBlockedTimer:
    "Riprova tra: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "Il profilo sarà sbloccato tra <span class='no-wrap text-black text-weight-medium'>{minutes} хв</span>",
  profileBlockedBtn: "Chiaro",

  // Recovery not found
  recoveryNotFoundTitle: "Registra un nuovo profilo",
  recoveryNotFoundText1:
    "Non hai inserito i contatti aggiuntivi, perciò non possiamo ripristinarti l'accesso al profilo con il numero <span class='no-wrap text-black text-weight-medium'>+{phone}</span>",
  recoveryNotFoundText2:
    "Ti preghiamo di registrarti di nuovo con lo stesso numero. Le informazioni sulle tue spedizioni saranno conservate, ma non potremo conservare i punti maturati",
  recoveryNotFoundBtn: "Registrarsi",
  recoveryNotNowBtn: "Non ora",

  recoveryNotFoundText1Another:
    "Non hai l'accesso ai contatti perciò non possiamo ripristinarti l'accesso al profilo con il numero <span class='no-wrap text-black text-weight-medium'>+{phone}</span>.",

  // Password changed
  passwordChangedTitle: "La password è modificata",
  passwordChangedText1:
    "Usa la password solo sul sito novapost.com e nell'applicazione.",
  passwordChangedText2:
    "Non comunicare la password a nessuno. I nostri dipendenti non la chiedono mai",
  passwordChangedBtn: "Chiaro",

  // Error limit 10
  errorLimit10PageTitle: "Il tuo numero è temporaneamente bloccato",
  errorLimit10PageTextWithNumber:
    "Non è stato possibile confermare il tuo numero tramite codice oppure i tuoi messaggi non sono stati recapitati a causa di una connessione instabile.",
  errorLimit10PageTimer:
    "Si prega di riprovare tra <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  errorLimit10PageBtn: "Torna alla pagina iniziale",

  // Error Reg Limit
  errorRegLimitTitle: "Prova più tardi",
  errorRegLimitText:
    "La quantità di registrazioni per un solo numero è limitata. Ti prehiamo di provare a registrarti di nuovo tra 4 ore",
  errorRegLimitBtn: "Torna alla pagina iniziale",

  // Error unforeseen
  errorUnforeseenTitle: "Ha avuto luogo un errore",
  errorUnforeseenText: "Riprova ad effettuare un nuovo accesso",
  errorUnforeseenBtn: "Chiaro",

  // Bot dialog
  botDialogTitle: "Si è verificato un errore",
  botDialogText: "Per favore, riprova ad effettuare l'accesso",
  botDialogBtn: "Capito",

  // Email for login not found
  emailNotFoundTitile: "Purtroppo nessun profilo trovato per questa email",
  emailNotFoundText: "È necessario autenticarsi tramite numero di telefono",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "Questa opzione è disponibile solo per i rappresentanti di persone giuridiche",
  loginByEmailDeclinedText:
    "È necessario autenticarsi tramite numero di telefono",
};

export default it;
