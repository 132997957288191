const fr = {
  accept: "J'ai compris",
  next: "Continuer",
  back: "Retour",
  skip: "Passer",
  language: "Langue",
  loading: "Chargement...",

  // Errors
  wrongRepeat: "Les mots de passe ne correspondent pas",
  requireField: "Ceci est un champ obligatoire",
  errorFioField: "Vérifiez l'exactitude des données saisies",
  pinMust4: "Doit contenir au moins 4 caractères",
  pinMust6: "Doit contenir au moins 6 caractères",
  must4: "Il doit y avoir 4 chiffres",
  must6: "Il doit y avoir 6 chiffres",
  must9: "Il doit y avoir 9 chiffres",
  protectionPhoneMainError: "Correspond au numéro principal",
  mustBe: "Il doit y avoir ",
  mustBeEnd: " chiffres",
  unknownOperator: "Seulement les numéros de téléphone mobile",
  contactRepeats: "Le nouveau contact correspond à celui précédemment indiqué",
  errorOldBrowserTitle: "Mettez à jour votre navigateur",
  errorOldBrowserText:
    "Vous utilisez actuellement une version obsolète du navigateur. Veuillez mettre à jour votre navigateur pour continuer",
  disableCookiesTitle: "Erreur de connexion",
  disableCookiesText:
    "Il est possible que les cookies soient désactivés dans le navigateur que vous utilisez. Activez l'autorisation dans les paramètres du navigateur et réessayez",
  enterEmailError: "Mauvais e-mail",
  errorPageTitle502: "Le service est temporairement indisponible",
  errorPageText502: "Essayez de vous connecter un peu plus tard",
  latNameError: "S'il vous plaît, remplissez en caractères latins",
  wrongOtp:
    "Vous avez saisi un code incorrect. Il reste {attempts} tentative(s)",
  wrongPassword:
    "Vous avez saisi le mauvais mot de passe. Il reste {attempts} tentative(s)",

  // Phone input
  inputCountry: "Pays",
  inputCountryName: "Nom du pays",
  inputFound: "Résultats de recherche",
  inputNotFound:
    "Nous n'avons rien trouvé pour la requête <span>”{text}”</span>",

  // Email input
  emailInputLabel: "E-mail",

  // Start
  startTitle: "Bienvenue chez Nova Post!",
  enterTitle: "Entrez votre numéro de téléphone",
  enterEmailTitle: "Entrez votre adresse e-mail",
  enterAcceptBtn: "Envoyer le code",
  enterText:
    "Vous pouvez vous connecter ou créer un compte si vous êtes nouveau chez Nova Post.",
  enterTextEmail: "Pour vous connecter, saisissez votre adresse e-mail",
  enterOrRegistration: "Connexion",
  shouldRegistration:
    "Connectez-vous et accédez aux paramètres avancés, commandez des services supplémentaires et payez-les en ligne.",
  enterOr: "ou",
  enterPopupBtnEmail: "Se connecter avec email",
  enterPopupBtnPhone: "Se connecter avec un numéro de téléphone",

  // Social
  socialAppleBtn: "Continuer avec Apple",
  socialGoogleBtn: "Continuer avec Google",
  socialDeclinePopupTitle: "Impossible de s'authentifier",
  socialDeclinePopupText:
    "Malheureusement, il n'y a pas de compte correspondant. Veuillez vous authentifier en utilisant votre numéro de téléphone, puis ajoutez votre compte Google ou Apple dans les paramètres de profil.",
  socialDeclinePopupBtn: "Compris",

  // Enter Otp
  enterOtpTitle: "Entrez le code du SMS",
  enterOtpTitleEmail: "Entrez le code de l'e-mail",
  enterOtpTextPhone:
    "Nous avons envoyé le code de confirmation au numéro <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span>",
  enterOtpTextEmail:
    "Nous avons envoyé le code de confirmation à l'adresse e-mail <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span>",
  enterOtpSocialTitle:
    "Entrez votre numéro de téléphone pour vous connecter avec {process}",
  enterOtpSocialText:
    "Le numéro de téléphone est requis pour la première connexion avec {process} afin d'afficher les informations sur vos colis. Pour les prochaines connexions, il ne sera plus nécessaire de saisir le numéro de téléphone.",
  enterOtpCalling: "Nous vous appelons",
  resendOtp: "Renvoyer le code",
  resendOtpByCall: "Il vaut mieux que vous appeliez",
  resendOtpAfter: "Renvoyez à nouveau dans 0:{time}",
  enterOtp502Title: "Malheureusement, le numéro indiqué ne correspond pas",
  enterOtp502Text:
    "Peut-être en raison d'une mauvaise connexion. Essayez encore une fois",

  // Enter password
  enterPasswordTitle: "Entrez le mot de passe",
  enterPasswordText: "Ce mot de passe a été fourni lors de l'inscription",
  enterPasswordLabel: "Mot de passe",
  enterPasswordForgot: "Mot de passe oublié?",
  passwordPopupTitle: "Mot de passe incorrect",
  passwordPopupText:
    "Vous ou quelqu'un d'autre avez saisi plusieurs fois un mot de passe incorrect. En raison de sécurité, nous avons bloqué le profil.",
  passwordPopupBtn1: "Retourner à l'écran d'accueil",

  // Create password
  createPasswordTitle: "Créez un nouveau mot de passe",
  createPasswordText:
    "Pour le numéro <br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Mot de passe",
  createPasswordInput1Hint: "Doit contenir au moins 6 caractères",
  createPasswordInput2Label: "Veuillez répéter le mot de passe",

  // Registration
  registrationTitle: "Créer un profil",
  registrationText:
    "Pour vous inscrire, veuillez saisir votre numéro de téléphone et créer un mot de passe",
  registrationInput1Label: "Mot de passe",
  registrationInput1Hint: "Doit contenir au moins 6 caractères",
  registrationInput2Label: "Veuillez répéter le mot de passe",
  registrationPolicy: `En continuant, je confirme que j'ai pris connaissance de la {0} et j'accepte le traitement de mes`,
  registrationPolicyLink: `Politique de confidentialité`,
  registrationPolicy1: `En continuant, je confirme que je suis au courant de`,
  registrationPolicy2: `La politique de confidentialité`,
  registrationPolicy3: `et j'accepte le traitement de mes données personnelles`,
  registration: "",

  // Full name
  fullNameTitle: "Créez un profil",
  fullNameText: "Pour recevoir et envoyer des colis.",
  fullNameBtn: "S'inscrire",
  fullNameFirst: "Prénom",
  fullNameLast: "Nom",
  fullNameMiddle: "Patronyme",
  fullNameCheckbox: "Il n'y a pas de patronyme dans les documents",

  // Create recovery
  createRecoveryTitle: "Protégez votre profil",
  createRecoveryText:
    "Remplissez l'un des contacts supplémentaires (votre e-mail ou numéro supplémentaire) afin de pouvoir récupérer votre profil plus rapidement si nécessaire.",
  createRecoveryHint:
    "Le numéro de téléphone ne doit pas correspondre à celui saisi lors de l'inscription",

  // Choose recovery
  chooseRecoveryTitle: "Réinitialisation de mot de passe",
  chooseRecoveryText:
    "Sélectionnez un contact et attendez le code de confirmation",
  chooseRecoveryGreyBtn: "Je ne peux pas recevoir le code",

  // Profile blocked
  profileBlockedTitle: "Accès à l'application temporairement restreint",
  profileBlockedText:
    "Vous avez utilisé toutes les tentatives pour entrer le code. Veuillez réessayer dans 10 minutes.",
  profileBlockedTimer:
    "Réessayez dans: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "Le profil sera débloqué via <span class='no-wrap text-black text-weight-medium'>{minutes} хв</span>",
  profileBlockedBtn: " J'ai compris ",

  // Recovery not found
  recoveryNotFoundTitle: "Créez un nouveau profil",
  recoveryNotFoundText1:
    "Vous n'avez pas ajouté de contacts supplémentaires, donc nous ne pouvons pas restaurer votre accès au profil avec le numéro: <span class='no-wrap text-black text-weight-medium'>+{phone}</span>",
  recoveryNotFoundText2:
    "S'il vous plaît, veuillez vous inscrire à nouveau avec le même numéro. Les informations sur vos envois resteront, mais nous ne pourrons pas conserver les points accumulés",
  recoveryNotFoundBtn: "S'inscrire",
  recoveryNotNowBtn: "Pas maintenant",

  recoveryNotFoundText1Another:
    "Vous n'avez pas accès aux contacts, donc nous ne pouvons pas restaurer votre accès au profil avec le numéro: <span class='no-wrap text-black text-weight-medium'>+{phone}</span>.",

  // Password changed
  passwordChangedTitle: "Le mot de passe a été modifié",
  passwordChangedText1:
    "Utilisez le mot de passe uniquement sur le site novapost.com et sur l'application.",
  passwordChangedText2:
    "Ne partagez pas votre mot de passe avec qui que ce soit. Nos employés ne le demandent jamais",
  passwordChangedBtn: " J'ai compris ",

  // Error limit 10
  errorLimit10PageTitle: "Votre numéro est temporairement bloqué",
  errorLimit10PageTextWithNumber:
    "Nous n'avons pas réussi à confirmer votre numéro avec le code, ou vos messages n'ont pas été livrés en raison d'une connexion instable.",
  errorLimit10PageTimer:
    "S'il vous plaît, veuillez réessayer dans: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  errorLimit10PageBtn: "Retourner à l'écran d'accueil",

  // Error Reg Limit
  errorRegLimitTitle: "Essayez plus tard",
  errorRegLimitText:
    "Le nombre d'enregistrements par numéro est limité. S'il vous plaît, essayez de vous inscrire dans 4 heures",
  errorRegLimitBtn: "Retourner à l'écran d'accueil",

  // Error unforeseen
  errorUnforeseenTitle: "Une erreur est survenue",
  errorUnforeseenText: "Veuillez réessayer pour vous connecter",
  errorUnforeseenBtn: " J'ai compris ",

  // Bot dialog
  botDialogTitle: "Une erreur est survenue",
  botDialogText: "Veuillez réessayer pour vous connecter",
  botDialogBtn: " J'ai compris ",

  // Email for login not found
  emailNotFoundTitile: "Malheureusement, aucun profil trouvé pour cet e-mail",
  emailNotFoundText: "Vous devez vous authentifier via le numéro de téléphone",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "Cette option est disponible uniquement pour les représentants de personnes morales",
  loginByEmailDeclinedText:
    "Vous devez vous authentifier via le numéro de téléphone",
};

export default fr;
