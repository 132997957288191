const et = {
  accept: "Sain aru",
  next: "Jätka",
  back: "Tagasi",
  skip: "Jäta vahele",
  language: "Keel",
  loading: "Laadimine...",

  // Errors
  wrongRepeat: "Paroolid ei ühti",
  requireField: "See on kohustuslik väli",
  errorFioField: "Palun kontrollige sisestatud andmete õigsust",
  pinMust4: "Peab sisaldama vähemalt 4 sümbolit",
  pinMust6: "Peab sisaldama vähemalt 6 sümbolit",
  must4: "Peab olema 4 numbrit",
  must6: "Peab olema 6 numbrit",
  must9: "Peab olema 9 numbrit",
  protectionPhoneMainError: "Ühtib peamise telefoninumbriga",
  mustBe: "Peab olema ",
  mustBeEnd: " numbrit",
  unknownOperator: "Ainult mobiilitelefonide numbrid",
  contactRepeats: "Uus kontakt ühtib varem märgitud kontaktiga",
  errorOldBrowserTitle: "Värskendage oma veebilehitsejat",
  errorOldBrowserText:
    "Praegu kasutate veebilehitseja vanemat versiooni. Jätkamiseks värskendage veebilehitsejat.",
  enterEmailError: "Vale e-posti aadress",
  errorPageTitle502: "Teenus pole ajutiselt saadaval",
  errorPageText502: "Proovige ühendada hiljem uuesti",
  latNameError: "Palun kirjutage ladina tähtedega",
  wrongOtp: "Olete sisestanud vale koodi. Teil on jäänud {attempts} katset",
  wrongPassword:
    "Olete sisestanud vale parooli. Teil on jäänud {attempts} katset",

  // Phone input
  inputCountry: "Riik",
  inputCountryName: "Riigi nimi",
  inputFound: "Otsingu tulemused",
  inputNotFound: "Teie otsing <span>”{text}”</span> ei andnud tulemusi",

  // Email input
  emailInputLabel: "E-posti aadress",

  // Start
  startTitle: "Tere tulemast Nova Posta!",
  enterTitle: "Sisestage telefoninumber",
  enterEmailTitle: "Sisestage e-posti aadress",
  enterAcceptBtn: "Saada kood",
  enterText: "Saate sisse logida või luua konto, kui olete Nova Postis uus.",
  enterTextEmail: "Sisselogimiseks sisestage oma e-posti aadress",
  enterOrRegistration: "Sisene",
  shouldRegistration:
    "Sisenege süsteemi ja saate juurdepääsu täiendavatele seadetele, saate tellida lisateenuseid ja tasuda nende eest veebis.",
  enterOr: "või",
  enterPopupBtnEmail: "Logi sisse e-postiga",
  enterPopupBtnPhone: "Logi sisse telefoninumbriga",

  // Social
  socialAppleBtn: "Jätka Apple'iga",
  socialGoogleBtn: "Jätka Google'iga",
  socialDeclinePopupTitle: "Autentimine pole võimalik",
  socialDeclinePopupText:
    "Kahjuks sellist kontot ei ole. Palun autentige end oma telefoninumbri abil ja seejärel lisage oma Google'i või Apple'i konto profiili seadetes.",
  socialDeclinePopupBtn: "Arusaadav",

  // Enter Otp
  enterOtpTitle: "Sisestage SMS-kood",
  enterOtpTitleEmail: "Sisestage kood e-postist",
  enterOtpTextPhone:
    "Saatsime kinnituskoodi telefoninumbrile <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span>",
  enterOtpTextEmail:
    "Saatsime kinnituskoodi e-posti aadressile <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span>",
  enterOtpSocialTitle: "Sisestage telefoninumber, et sisse logida {process}-ga",
  enterOtpSocialText:
    "Telefoninumber on vajalik esimeseks sisselogimiseks {process}-ga, et kuvada teavet teie pakkide kohta. Tulevaste sisselogimiste jaoks pole telefoninumbri sisestamine vajalik.",
  enterOtpCalling: "Me helistame teile",
  resendOtp: "Saada kood uuesti",
  resendOtpByCall: "Parem helista",
  resendOtpAfter: "Saada uuesti 0 minuti pärast:{time}",
  enterOtp502Title: "Kahjuks märgitud telefoninumber ei vasta",
  enterOtp502Text: "See võis juhtuda halva ühenduse tõttu. Proovige uuesti.",

  // Enter password
  enterPasswordTitle: "Sisestage parool",
  enterPasswordText: "Selle parooli olete määranud registreerimisel",
  enterPasswordLabel: "Parool",
  enterPasswordForgot: "Unustasid parooli?",
  passwordPopupTitle: "Vale parool",
  passwordPopupText:
    "Teie või keegi teine on sisestanud korduvalt vale parooli. Turvalisuse huvides on teie konto lukustatud.",
  passwordPopupBtn1: "Tagasi avakuvale",

  // Create password
  createPasswordTitle: "Looge uus parool",
  createPasswordText:
    "Telefoninumbrile <br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Parool",
  createPasswordInput1Hint: "Peab sisaldama vähemalt 6 sümbolit",
  createPasswordInput2Label: "Sisestage parool uuesti",

  // Registration
  registrationTitle: "Konto loomine",
  registrationText:
    "Registreerumiseks sisestage oma telefoninumber ja määrake parool",
  registrationInput1Label: "Parool",
  registrationInput1Hint: "Peab olema vähemalt 6 sümbolit pikk",
  registrationInput2Label: "Sisestage parool uuesti",
  registrationPolicy: `Jätkates kinnitan, et olen tutvunud {0} ja annan nõusoleku oma isikuandmete töötlemiseks`,
  registrationPolicyLink: `Privaatsuspoliitikaga`,
  registrationPolicy1: `Jätkates kinnitan, et olen tutvunud`,
  registrationPolicy2: `Privaatsuspoliitikaga`,
  registrationPolicy3: `ja annan nõusoleku oma isikuandmete töötlemiseks`,
  registration: "",

  // Full name
  fullNameTitle: "Loo profiil",
  fullNameText: "Pakkide vastuvõtmiseks ja saatmiseks.",
  fullNameBtn: "Registreeru",
  fullNameFirst: "Eesnimi",
  fullNameLast: "Perekonnanimi",
  fullNameMiddle: "Isanimi",
  fullNameCheckbox: "Dokumentides ei ole isanime",

  // Create recovery
  createRecoveryTitle: "Kaitske oma kontot",
  createRecoveryText:
    "Lisage üks täiendav kontakt (oma e-posti aadress või täiendav telefoninumber), et vajadusel saaksite oma konto kiiremini taastada.",
  createRecoveryHint:
    "Telefoninumber ei tohi olla sama, mida olete kasutanud registreerumisel",

  // Choose recovery
  chooseRecoveryTitle: "Parooli taastamine",
  chooseRecoveryText: "Valige kontakt ja oodake kinnituskoodi",
  chooseRecoveryGreyBtn: "Ei saa kinnituskoodi",

  // Profile blocked
  profileBlockedTitle: "Juurdepääs rakendusele on ajutiselt piiratud",
  profileBlockedText:
    "Olete koodi sisestamise katsed ammendanud. Proovige uuesti 10 minuti pärast.",
  profileBlockedTimer:
    "Proovige uuesti pärast: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "Konto tehakse lahti: <span class='no-wrap text-black text-weight-medium'>{minutes} хв</span>",
  profileBlockedBtn: "Sain aru",

  // Recovery not found
  recoveryNotFoundTitle: "Looge uus konto",
  recoveryNotFoundText1:
    "Te pole sisestanud täiendavaid kontaktandmeid, seega me ei saa taastada juurdepääsu teie kontole telefoninumbri: <span class='no-wrap text-black text-weight-medium'>+{phone}</span> kaudu",
  recoveryNotFoundText2:
    "Palun looge sama telefoninumbriga uus konto. Teie saadetisi puudutav teave säilitatakse, kuid kogunenud punktid kaovad",
  recoveryNotFoundBtn: "Registreeru",
  recoveryNotNowBtn: "Mitte praegu",

  recoveryNotFoundText1Another:
    "Teil puudub juurdepääs kontaktidele, seega me ei saa taastada juurdepääsu teie kontole telefoninumbri: <span class='no-wrap text-black text-weight-medium'>+{phone}</span> kaudu.",

  // Password changed
  passwordChangedTitle: "Parool on muudetud",
  passwordChangedText1:
    "Kasutage oma parooli ainult veebilehel novapost.com ja rakenduses.",
  passwordChangedText2:
    "Ärge jagage parooli mitte kellegagi. Meie töötajad ei küsi seda mitte kunagi.",
  passwordChangedBtn: "Sain aru",

  // Error limit 10
  errorLimit10PageTitle: "Teie telefoninumber on ajutiselt lukustatud",
  errorLimit10PageTextWithNumber:
    "Meil ei õnnestunud teie telefoninumbri kinnitamine kinnituskoodi alusel või püsiva ühenduse puudumisel teie sõnumite saatmine ebaõnnestus.",
  errorLimit10PageTimer:
    "Palun proovige uuesti: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  errorLimit10PageBtn: "Tagasi avakuvale",

  // Error Reg Limit
  errorRegLimitTitle: "Proovige hiljem uuesti",
  errorRegLimitText:
    "Ühe telefoninumbriga tehtud registreerimiste arv on piiratud. Palun proovige registreeruda 4 tunni jooksul uuesti",
  errorRegLimitBtn: "Tagasi avakuvale",

  // Error unforeseen
  errorUnforeseenTitle: "Ilmnes viga",
  errorUnforeseenText: "Palun sisenege uuesti",
  errorUnforeseenBtn: "Sain aru",

  // Bot dialog
  botDialogTitle: "Ilmnes viga",
  botDialogText: "Palun sisenege uuesti",
  botDialogBtn: "Sain aru",

  // Email for login not found
  emailNotFoundTitile: "Kahjuks pole selle e-posti aadressiga profiili leitud",
  emailNotFoundText: "Teil tuleb autoriseerida telefoni teel",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "See võimalus on saadaval ainult juriidiliste isikute esindajatele",
  loginByEmailDeclinedText: "Teil tuleb autoriseerida telefoni teel",
};

export default et;
