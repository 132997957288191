const uk = {
  accept: "Értem.",
  next: "Folytatás",
  back: "Vissza",
  skip: "Skip",
  language: "Nyelv",
  loading: "Betöltés...",

  // Errors
  wrongRepeat: "A jelszavak nem egyeznek",
  requireField: "Ez egy kötelező mező",
  errorFioField: "Ellenőrizze a megadott adatok helyességét",
  pinMust4: "Legalább 4 karaktert kell tartalmaznia",
  pinMust6: "Legalább 6 karaktert kell tartalmaznia",
  must4: "4 számjegynek kell lennie",
  must6: "6 számjegynek kell lennie",
  must9: "9 számjegynek kell lennie",
  protectionPhoneMainError: "Megegyezik az elsődleges számmal",
  mustBe: "Kell lennie ",
  mustBeEnd: " számjegyet",
  unknownOperator: "Csak mobilszámok",
  contactRepeats: "Az új szám megegyezik a korábban megadottal",
  errorOldBrowserTitle: "Frissítse a böngészőt",
  errorOldBrowserText:
    "Jelenleg a böngésző régebbi verzióját használja. Kérjük, frissítse böngészőjét a folytatáshoz",
  enterEmailError: "Helytelen e-mail cím",
  errorPageTitle502: "A szolgáltatás ideiglenesen nem érhető el",
  errorPageText502: "Próbáljon meg egy kicsit később csatlakozni",
  latNameError: "Kérjük, töltse ki  latin betűkkel",
  wrongOtp: "Helytelen kódot adott meg. Még {attempts} próbálkozás van hátra",
  wrongPassword:
    "Helytelen jelszót adott meg.  Még {attempts} próbálkozás van hátra",

  // Phone input
  inputCountry: "Ország",
  inputCountryName: "Ország neve",
  inputFound: "Keresési eredmények",
  inputNotFound: "Nem találtunk semmit <span>”{text}”lekérdezéshez </span>",

  // Email input
  emailInputLabel: "Email",

  // Start
  startTitle: "Üdvözöljük a Nova Postánál!",
  enterTitle: "Adja meg a telefonszámát",
  enterEmailTitle: "Adja meg az e-mail címét",
  enterAcceptBtn: "Kód küldése",
  enterText:
    "Bejelentkezhet, vagy létrehozhat egy fiókot, ha új a Nova Postánál.",
  enterTextEmail: "A bejelentkezéshez adja meg e-mail címét",
  enterOrRegistration: "Bejelentkezés",
  shouldRegistration:
    "Jelentkezzen be és érje el a speciális beállításokat, rendeljen további szolgáltatásokat, és fizessen értük online.",
  enterOr: "vagy",
  enterPopupBtnEmail: "Bejelentkezés e-maillel",
  enterPopupBtnPhone: "Bejelentkezés telefonszámmal",

  // Social
  socialAppleBtn: "Folytatás Apple-lal",
  socialGoogleBtn: "Folytatás Google-lel",
  socialDeclinePopupTitle: "Nem lehet bejelentkezni",
  socialDeclinePopupText:
    "Sajnáljuk, nincs ilyen fiók. Kérjük, azonosítsa magát telefonos számával, majd adja hozzá Google vagy Apple fiókját a profilbeállításokban.",
  socialDeclinePopupBtn: "Értem",

  // Enter Otp
  enterOtpTitle: "Adja meg az SMS kódját",
  enterOtpTitleEmail: "Adja meg az e-mailben található kódot",
  enterOtpTextPhone:
    "Küldtünk egy megerősítő kódot a <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span> számra",
  enterOtpTextEmail:
    "Küldtünk egy megerősítő kódot e-mailre<br /><span class='no-wrap text-black white'>{email}</span>",
  enterOtpSocialTitle:
    "Adja meg a telefonszámát, hogy bejelentkezhessen a {process}-szel",
  enterOtpSocialText:
    "A telefonszámot az első bejelentkezéskor kell megadni a {process} használatához, hogy megjelenjenek a csomagjaival kapcsolatos információk. A jövőbeni bejelentkezéseknél már nem lesz szükség a telefonszám megadására.",
  enterOtpCalling: "Hívunk téged",
  resendOtp: "Kód újraküldése",
  resendOtpByCall: "Jobb telefonálj",
  resendOtpAfter: "Küldés újra 0 múlva:{time}",
  enterOtp502Title: "Sajnos a megadott szám nem válaszol",
  enterOtp502Text: "Talán a rossz összeköttetés miatt. Próbálja meg újra.",

  // Enter password
  enterPasswordTitle: "Írja be a jelszót",
  enterPasswordText: "Ezt a jelszót adta meg a regisztráció során",
  enterPasswordLabel: "Jelszó",
  enterPasswordForgot: "Elfelejtette a jelszót?",
  passwordPopupTitle: "Helytelen jelszó",
  passwordPopupText:
    "Ön vagy valaki más többször helytelen jelszót adott meg. A védelem érdekében blokkoltuk a profilt.",
  passwordPopupBtn1: "Vissza a kezdőképernyőre",

  // Create password
  createPasswordTitle: "Új jelszó létrehozása",
  createPasswordText:
    "A számhoz <br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Jelszó",
  createPasswordInput1Hint: "Legalább 6 karaktert kell tartalmaznia",
  createPasswordInput2Label: "Ismételje meg a jelszót",

  // Registration
  registrationTitle: "Profil létrehozása",
  registrationText:
    "A regisztrációhoz írja be telefonszámát és hozzon létre egy jelszót",
  registrationInput1Label: "Jelszó",
  registrationInput1Hint: "Legalább 6 karaktert kell tartalmaznia",
  registrationInput2Label: "Ismételje meg a jelszót",
  registrationPolicy: `Folytatva, megerősítem, hogy ismerem az {0}, és hozzájárulok személyes adataim feldolgozásához`,
  registrationPolicyLink: `Adatvédelmi szabályzatot`,
  registrationPolicy1: `Folytatva, megerősítem, hogy ismerem az`,
  registrationPolicy2: `Adatvédelmi szabályzatot`,
  registrationPolicy3: ` és hozzájárulok személyes adataim feldolgozásához`,
  registration: "",

  // Full name
  fullNameTitle: "Hozzon létre profilt",
  fullNameText: "A csomagok fogadásához és küldéséhez.",
  fullNameBtn: "Regisztráció",
  fullNameFirst: "Név",
  fullNameLast: "Vezetéknév",
  fullNameMiddle: "Utónév",
  fullNameCheckbox: "A dokumentumokban nincs utónév",

  // Create recovery
  createRecoveryTitle: "Védje meg profilját",
  createRecoveryText:
    "Adja meg a további kapcsolatok egyikét (e-mail címét vagy további telefonszámát), hogy szükség esetén gyorsabban visszaállítsa profilját.",
  createRecoveryHint:
    "A telefonszám nem egyezhet a regisztráció során megadott telefonszámmal",

  // Choose recovery
  chooseRecoveryTitle: "Jelszó visszaállítás",
  chooseRecoveryText:
    "Válasszon ki egy számot, és várja meg a kód megerősítését",
  chooseRecoveryGreyBtn: "Nem tudom megszerezni a kódot",

  // Profile blocked
  profileBlockedTitle:
    "Az alkalmazáshoz való hozzáférés ideiglenesen korlátozva",
  profileBlockedText:
    "Minden kódbeviteli próbálkozást kimerített. Próbálja meg újra 10 perc múlva.",
  profileBlockedTimer:
    "Próbálja újra: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "A profil feloldódik <span> class=' no-wrap text-black text-weight-medium '> {minutes} perc múlva </span>",
  profileBlockedBtn: "Értem.",

  // Recovery not found
  recoveryNotFoundTitle: "Regisztráljon egy új profilt",
  recoveryNotFoundText1:
    "Nem adtál hozzá további telefonszámot vagy e-mailt, így nem tudjuk visszaállítani a profiljához való hozzáférést a következő szám szerint: <span class='no-wrap text-black text-weight-medium'>+{phone}</span>",
  recoveryNotFoundText2:
    "Kérjük, regisztráljon újra ugyanazon a számon. A csomagjaira vonatkozó információk megmaradnak, de a felhalmozott pontokat nem tudjuk elmenteni.",
  recoveryNotFoundBtn: "Regisztráció",
  recoveryNotNowBtn: "Nem most",

  recoveryNotFoundText1Another:
    "Nincs hozzáférése névjegyeihez, ezért nem tudjuk visszaállítani a profilhoz való hozzáférést a következő szám szerint: <span class='no-wrap text-black text-weight-medium'>+{phone}</span>.",

  // Password changed
  passwordChangedTitle: "Jelszó megváltoztatva",
  passwordChangedText1:
    "A jelszót csak a novapost.com webhelyen és az alkalmazásban használja.",
  passwordChangedText2:
    "Ne ossza meg senkivel a jelszavát. Munkatársaink soha nem kérdezik kódot",
  passwordChangedBtn: "Értem.",

  // Error limit 10
  errorLimit10PageTitle: "A száma ideiglenesen blokkolva van",
  errorLimit10PageTextWithNumber:
    "Nem tudtuk megerősíteni a számát kóddal, vagy az üzeneteit instabil kapcsolat miatt nem kézbesítették.",
  errorLimit10PageTimer:
    "Kérjük, próbálja újra múlva: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  errorLimit10PageBtn: "Vissza a kezdőképernyőre",

  // Error Reg Limit
  errorRegLimitTitle: "Próbálja újra később",
  errorRegLimitText:
    "A regisztrációk száma számonként korlátozott. Kérjük, próbáljon regisztrálni 4 órán belül",
  errorRegLimitBtn: "Vissza a kezdőképernyőre",

  // Error unforeseen
  errorUnforeseenTitle: "Hiba történt",
  errorUnforeseenText: "Próbálja meg újra, hogy belépjen",
  errorUnforeseenBtn: "Értem.",

  // Bot dialog
  botDialogTitle: "Hiba történt",
  botDialogText: "Próbálja meg újra, hogy belépjen",
  botDialogBtn: "Értem.",

  // Email for login not found
  emailNotFoundTitile: "Sajnos nem található profil ehhez az e-mail címhez",
  emailNotFoundText: "Telefonszámon keresztüli hitelesítés szükséges",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "Ez az opció csak jogi személyek képviselői számára elérhető",
  loginByEmailDeclinedText: "Telefonszámon keresztüli hitelesítés szükséges",
};

export default uk;
