const de = {
  accept: "OK",
  next: "Weiter",
  back: "Zurück",
  skip: "Überspringen",
  language: "Sprache",
  loading: "Herunterladen...",

  // Errors
  wrongRepeat: "Passwörter stimmen nicht überein",
  requireField: "Dies ist ein Pflichtfeld",
  errorFioField: "Prüfen Sie, ob die eingegebenen Daten korrekt sind",
  pinMust4: "Muss 4 oder mehr Zeichen enthalten",
  pinMust6: "Muss 6 oder mehr Zeichen enthalten",
  must4: "Es sollten 4 Ziffern sein",
  must6: "Es sollten 6 Ziffern sein",
  must9: "Es sollten 9 Ziffern sein",
  protectionPhoneMainError: "Entspricht der Hauptnummer",
  mustBe: "Es sollte sein ",
  mustBeEnd: " Ziffern",
  unknownOperator: "Nur Mobiltelefonnummer",
  contactRepeats: "Der neue Kontakt stimmt mit dem zuvor angegebenen überein",
  errorOldBrowserTitle: "Aktualisieren Sie Ihren Browser",
  errorOldBrowserText:
    "Sie verwenden derzeit eine ältere Version des Browsers. Bitte aktualisieren Sie Ihren Browser, um fortzufahren",
  enterEmailError: "Ungültige E-Mail-Adresse",
  errorPageTitle502: "Das Service ist vorübergehend nicht verfügbar",
  errorPageText502: "Versuchen Sie später erneut, eine Verbindung herzustellen",
  latNameError: "Bitte geben Sie das lateinische Alphabet ein",
  wrongOtp:
    "Sie haben den falschen Code eingegeben. Verbleibende(r) {attempts} Versuch(e)",
  wrongPassword:
    "Sie haben das falsche Passwort eingegeben. Verbleibende(r) {attempts} Versuch(e)",

  // Phone input
  inputCountry: "Land",
  inputCountryName: "Landesname",
  inputFound: "Suchergebnisse",
  inputNotFound:
    "Wir haben nichts für die Abfrage <span>”{text}”</span> gefunden",

  // Email input
  emailInputLabel: "E-Mail",

  // Start
  startTitle: "Willkommen bei Nova Post!",
  enterTitle: "Geben Sie die Telefonnummer ein",
  enterEmailTitle: "Geben Sie die E-Mail-Adresse ein",
  enterAcceptBtn: "Code senden",
  enterText:
    "Sie können sich anmelden oder ein Konto erstellen, wenn Sie zum ersten Mal bei Nova Post sind.",

  enterTextEmail: "Geben Sie Ihre E-Mail-Adresse ein, um sich anzumelden",
  enterOrRegistration: "Anmelden",
  shouldRegistration:
    "Melden Sie sich an und greifen Sie auf erweiterte Einstellungen zu, bestellen Sie zusätzliche Dienste und bezahlen Sie diese online.",
  enterOr: "oder",
  enterPopupBtnEmail: "Mit E-Mail anmelden",
  enterPopupBtnPhone: "Mit Telefonnummer anmelden",

  // Social
  socialAppleBtn: "Weiter mit Apple",
  socialGoogleBtn: "Weiter mit Google",
  socialDeclinePopupTitle: "Anmeldung nicht möglich",
  socialDeclinePopupText:
    "Leider gibt es keinen solchen Account. Bitte authentifizieren Sie sich mit Ihrer Telefonnummer und fügen Sie dann Ihren Google- oder Apple-Account in den Profil-Einstellungen hinzu.",
  socialDeclinePopupBtn: "Verstanden",

  // Enter Otp
  enterOtpTitle: "Geben Sie den Code aus der SMS ein",
  enterOtpTitleEmail: "Geben Sie den Code aus der E-Mail ein",
  enterOtpTextPhone:
    "Wir haben einen Bestätigungscode an <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span> gesendet",
  enterOtpTextEmail:
    "Wir haben einen Bestätigungscode an <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span> gesendet",
  enterOtpSocialTitle:
    "Geben Sie Ihre Telefonnummer an, um sich mit {process} anzumelden",
  enterOtpSocialText:
    "Die Telefonnummer wird für die erste Anmeldung mit {process} benötigt, um Informationen zu Ihren Paketen anzuzeigen. Bei künftigen Anmeldungen ist keine Telefonnummer mehr erforderlich.",
  enterOtpCalling: "Wir rufen Sie an",
  resendOtp: "Code erneut senden",
  resendOtpByCall: "Rufen Sie lieber an.",
  resendOtpAfter: "Erneut senden nach 0:{time}",
  enterOtp502Title:
    "Die von Ihnen angegebene Nummer ist leider nicht erreichbar",
  enterOtp502Text:
    "Vielleicht wegen der schlechten Verbindung. Versuchen Sie es erneut",

  // Enter password
  enterPasswordTitle: "Passwort eingeben",
  enterPasswordText:
    "Dies ist das Passwort, das Sie bei der Registrierung eingegeben haben",
  enterPasswordLabel: "Passwort",
  enterPasswordForgot: "Haben Sie Ihr Passwort vergessen?",
  passwordPopupTitle: "Falsches Passwort",
  passwordPopupText:
    "Sie oder eine andere Person haben mehrmals ein falsches Passwort eingegeben, und wir haben Ihr Konto zum Schutz gesperrt.",
  passwordPopupBtn1: "Zurück zum Startbildschirm",

  // Create password
  createPasswordTitle: "Neues Passwort erstellen",
  createPasswordText:
    "Für die Nummer <br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Passwort",
  createPasswordInput1Hint: "Muss 6 oder mehr Zeichen enthalten",
  createPasswordInput2Label: "Wiederholen Sie das Passwort",

  // Registration
  registrationTitle: "Ein Profil erstellen",
  registrationText:
    "Um sich zu registrieren, geben Sie Ihre Telefonnummer ein und erstellen Sie ein Passwort",
  registrationInput1Label: "Passwort",
  registrationInput1Hint: "Muss mindestens 6 Zeichen enthalten",
  registrationInput2Label: "Wiederholen Sie das Passwort",
  registrationPolicy: `Indem ich fortfahre, bestätige ich, dass ich die {0} gelesen habe und mit der Verarbeitung meiner persönlichen Daten einverstanden bin`,
  registrationPolicyLink: `Datenschutzerklärung`,
  registrationPolicy1: `Mit dem Absenden bestätige ich, dass ich die`,
  registrationPolicy2: `Datenschutzerklärung`,
  registrationPolicy3: `gelesen habe und mit der Verarbeitung meiner persönlichen Daten einverstanden bin`,
  registration: "",

  // Full name
  fullNameTitle: "Erstellen Sie ein Profil",
  fullNameText: "Zum Empfangen und Versenden von Paketen.",
  fullNameBtn: "Registrieren",
  fullNameFirst: "Vorname",
  fullNameLast: "Nachname",
  fullNameMiddle: "Vatersname",
  fullNameCheckbox: "Kein Vatersname im Ausweis",

  // Create recovery
  createRecoveryTitle: "Schützen Sie Ihr Profil",
  createRecoveryText:
    "Geben Sie einen der zusätzlichen Kontakte ein (Ihre E-Mail oder eine zusätzliche Nummer), um Ihr Profil bei Bedarf schneller wieder zum Laufen zu bringen.",
  createRecoveryHint:
    "Die Telefonnummer darf nicht mit der bei der Registrierung eingegebenen Nummer übereinstimmen",

  // Choose recovery
  chooseRecoveryTitle: "Passwort wiederherstellen",
  chooseRecoveryText:
    "Wählen Sie einen Kontakt und warten Sie auf den Bestätigungscode",
  chooseRecoveryGreyBtn: "Ich kann den Code nicht bekommen",

  // Profile blocked
  profileBlockedTitle: "Zugriff auf die App vorübergehend eingeschränkt",
  profileBlockedText:
    "Sie haben alle Versuche zur Codeeingabe aufgebraucht. Versuchen Sie es in 10 Minuten erneut.",
  profileBlockedTimer:
    "Versuchen Sie es erneut in: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "Das Profil wird in <span class='no-wrap text-black text-weight-medium'>{minutes} min</span> freigegeben",
  profileBlockedBtn: "OK",

  // Recovery not found
  recoveryNotFoundTitle: "Ein neues Profil erstellen",
  recoveryNotFoundText1:
    "Sie haben keine weiteren Kontakte hinzugefügt, daher können wir den Zugriff auf Ihr Profil unter <span class='no-wrap text-black text-weight-medium'>+{phone}</span> nicht wiederherstellen",
  recoveryNotFoundText2:
    "Bitte registrieren Sie sich erneut mit der gleichen Nummer. Die Angaben über Ihre Pakete bleiben erhalten, aber wir können die gesammelten Punkte nicht speichern",
  recoveryNotFoundBtn: "Registrieren",
  recoveryNotNowBtn: "Nicht jetzt",

  recoveryNotFoundText1Another:
    "Sie können nicht auf Ihre Kontakte zugreifen, daher können wir Ihren Zugang zu Ihrem Profil unter <span class='no-wrap text-black text-weight-medium'>+{phone}</span> nicht wiederherstellen.",

  // Password changed
  passwordChangedTitle: "Passwort geändert",
  passwordChangedText1:
    "Verwenden Sie das Passwort nur auf der Website novapost.com und in der App.",
  passwordChangedText2:
    "Geben Sie Ihr Passwort nicht an andere weiter. Unsere Mitarbeiter fragen danach nicht",
  passwordChangedBtn: "OK",

  // Error limit 10
  errorLimit10PageTitle: "Ihre Nummer ist vorübergehend gesperrt",
  errorLimit10PageTextWithNumber:
    "Wir konnten Ihre Nummer nicht per Code bestätigen, oder Ihre Nachrichten wurden aufgrund einer instabilen Verbindung nicht zugestellt.",
  errorLimit10PageTimer:
    "Bitte versuchen Sie es in <span class='no-wrap text-black text-weight-medium'>{time}</span> erneut",
  errorLimit10PageBtn: "Zurück zum Startbildschirm",

  // Error Reg Limit
  errorRegLimitTitle: "Versuchen Sie es später erneut",
  errorRegLimitText:
    "Die Anzahl der Anmeldungen pro Nummer ist begrenzt. Bitte versuchen Sie es in 4 Stunden erneut.",
  errorRegLimitBtn: "Zurück zum Startbildschirm",

  // Error unforeseen
  errorUnforeseenTitle: "Da liegt ein Fehler vor",
  errorUnforeseenText: "Bitte versuchen Sie erneut, sich anzumelden",
  errorUnforeseenBtn: "OK",

  // Bot dialog
  botDialogTitle: "Ein Fehler ist aufgetreten",
  botDialogText: "Bitte versuchen Sie erneut, sich einzuloggen",
  botDialogBtn: "Verstanden",

  // Email for login not found
  emailNotFoundTitile:
    "Leider wurde kein Profil für diese E-Mail-Adresse gefunden",
  emailNotFoundText: "Sie müssen sich mit Ihrer Telefonnummer autorisieren",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "Diese Option steht nur Vertretern juristischer Personen zur Verfügung",
  loginByEmailDeclinedText:
    "Sie müssen sich mit Ihrer Telefonnummer autorisieren",
};

export default de;
