const es = {
  accept: "Entendido",
  next: "Seguir",
  back: "Atrás",
  skip: "Saltar",
  language: "Idioma",
  loading: "Cargando...",

  // Errors
  wrongRepeat: "Las contraseñas no coinciden",
  requireField: "Campo obligatorio",
  errorFioField: "Verifique la validez de los datos",
  pinMust4: "4 caracteres como mínimo",
  pinMust6: "6 caracteres como mínimo",
  must4: "Debe tener 4 dígitos",
  must6: "Debe tener 6 dígitos",
  must9: "Debe tener 9 dígitos",
  protectionPhoneMainError: "Coincide con el número principal",
  mustBe: "Debe tener ",
  mustBeEnd: " dígitos",
  unknownOperator: "Sólo números de celular",
  contactRepeats: "El contacto nuevo coincide con el anterior",
  errorOldBrowserTitle: "Actualice su navegador",
  errorOldBrowserText:
    "Está utilizando una versión anterior de su navegador. Actualice su navegador para continuar",
  enterEmailError: "Correo electrónico incorrecto",
  errorPageTitle502: "Servicio no disponible temporalmente",
  errorPageText502: "Intente conectarse más tarde",
  latNameError: "Por favor, use caracteres latinos",
  wrongOtp: "Ha introducido un código incorrecto. Quedan {attempts} intento(s)",
  wrongPassword:
    "Ha ingresado una contraseña incorrecta. Quedan {attempts} intento(s)",

  // Phone input
  inputCountry: "País",
  inputCountryName: "Nombre del país",
  inputFound: "Resultados de búsqueda",
  inputNotFound:
    "No encontramos nada acorde con la petición <span>”{text}”</span>",

  // Email input
  emailInputLabel: "Email",

  // Start
  startTitle: "¡Bienvenido a Nova Post!",
  enterTitle: "Ingrese su número de teléfono",
  enterEmailTitle: "Ingrese su correo electrónico",
  enterAcceptBtn: "Enviar código",
  enterText:
    "Puede iniciar sesión o crear una cuenta si es nuevo en Nova Post.",
  enterTextEmail: "Para iniciar sesión, ingresa tu correo electrónico",
  enterOrRegistration: "Inicio de sesión",
  shouldRegistration:
    "Inicie sesión y acceda a las opciones avanzadas, solicite servicios adicionales y páguelos en línea.",
  enterOr: "o",
  enterPopupBtnEmail: "Iniciar sesión con correo electrónico",
  enterPopupBtnPhone: "Iniciar sesión con número de teléfono",

  // Enter Otp
  enterOtpTitle: "Ingrese el código de SMS",
  enterOtpTitleEmail: "Ingrese el código del correo electrónico",
  enterOtpTextPhone:
    "Hemos enviado un código de confirmación al número <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span>",
  enterOtpTextEmail:
    "Hemos enviado un código de confirmación al email <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span>",
  enterOtpSocialTitle:
    "Ingrese su número de teléfono para iniciar sesión con {process}",
  enterOtpSocialText:
    "El número de teléfono es necesario para el primer inicio de sesión con {process} para mostrar la información de sus paquetes. En futuros inicios de sesión, no será necesario ingresar el número de teléfono.",
  enterOtpCalling: "Le estamos llamando",
  resendOtp: "Enviar el código de nuevo",
  resendOtpByCall: "Mejor llámeme",
  resendOtpAfter: "Volver a enviar en 0:{time}",
  enterOtp502Title: "Lamentablemente el número indicado no responde",
  enterOtp502Text: "Tal vez, por mala conexión. Inténtelo otra vez",

  // Enter password
  enterPasswordTitle: "Ingrese su contraseña",
  enterPasswordText: "Esta es la contraseña que ha utilizado para registrarse",
  enterPasswordLabel: "Contraseña",
  enterPasswordForgot: "¿Ha olvidado su contraseña?",
  passwordPopupTitle: "Contraseña incorrecta",
  passwordPopupText:
    "Usted u otra persona ha ingresado una contraseña incorrecta varias veces. Para protegerlo, hemos bloqueado su perfil.",
  passwordPopupBtn1: "Volver a la pantalla inicial",

  // Create password
  createPasswordTitle: "Cree una nueva contraseña",
  createPasswordText:
    "Para el número <br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Contraseña",
  createPasswordInput1Hint: "6 caracteres como mínimo",
  createPasswordInput2Label: "Repita la contraseña",

  // Registration
  registrationTitle: "Crear perfil",
  registrationText:
    "Para registrarse, ingrese su número de teléfono y cree una contraseña",
  registrationInput1Label: "Contraseña",
  registrationInput1Hint: "Al menos 6 caracteres",
  registrationInput2Label: "Repita la contraseña",
  registrationPolicy: `Al continuar declaro haber leído la {0} y acepto el procesamiento de mis datos personales`,
  registrationPolicy: `Política de privacidad`,
  registrationPolicy1: `Al continuar declaro haber leído la`,
  registrationPolicy2: `Política de privacidad`,
  registrationPolicy3: `y acepto el procesamiento de mis datos personales`,
  registration: "",

  // Full name
  fullNameTitle: "Crea un perfil",
  fullNameText: "Para recibir y enviar paquetes.",
  fullNameBtn: "Registrarse",
  fullNameFirst: "Nombre",
  fullNameLast: "Apellido",
  fullNameMiddle: "Patronímico",
  fullNameCheckbox: "Sin patronímico en los documentos",

  // Create recovery
  createRecoveryTitle: "Proteja su perfil",
  createRecoveryText:
    "Complete uno de los contactos adicionales (su email o número suplementario) para poder restaurar su perfil más rápidamente en caso de necesidad.",
  createRecoveryHint:
    "El número de teléfono no debe coincidir con el facilitado durante el registro",

  // Choose recovery
  chooseRecoveryTitle: "Recuperar la contraseña",
  chooseRecoveryText:
    "Seleccione un contacto y espere el código de confirmación",
  chooseRecoveryGreyBtn: "No puedo recibir el código",

  // Profile blocked
  profileBlockedTitle: "Acceso a la aplicación temporalmente restringido",
  profileBlockedText:
    "Has agotado todos los intentos de ingresar el código. Inténtalo de nuevo en 10 minutos.",
  profileBlockedTimer:
    "Inténtalo de nuevo en: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "El perfil se desbloqueará en <span class='no-wrap text-black text-weight-medium'>{minutes} min</span>",
  profileBlockedBtn: "Entendido",

  // Recovery not found
  recoveryNotFoundTitle: "Registre un perfil nuevo",
  recoveryNotFoundText1:
    "No ha ingresado ningún contacto adicional, por ello no podemos restablecer el acceso a su perfil con el número <span class='no-wrap text-black text-weight-medium'>+{phone}</span>",
  recoveryNotFoundText2:
    "Vuelva a registrarse con el mismo número. Se guardará la información sobre sus paquetes, pero no podremos retener los puntos acumulados",
  recoveryNotFoundBtn: "Registrarse",
  recoveryNotNowBtn: "Otra vez",

  recoveryNotFoundText1Another:
    "No tiene acceso a sus contactos, por ello no podemos restablecer el acceso a su perfil con el número <span class='no-wrap text-black text-weight-medium'>+{phone}</span>.",

  // Password changed
  passwordChangedTitle: "Contraseña modificada",
  passwordChangedText1:
    "Utilice la contraseña sólo en el sitio web novaposhta.ua y en la aplicación.",
  passwordChangedText2:
    "No comparta su contraseña con nadie. Nuestros empleados nunca la solicitan",
  passwordChangedBtn: "Entendido",

  // Error limit 10
  errorLimit10PageTitle: "Su número ha sido bloqueado temporalmente",
  errorLimit10PageTextWithNumber:
    "No hemos logrado confirmar su número por código o, debido a una conexión inestable, sus mensajes no han sido enviados.",
  errorLimit10PageTimer:
    "Por favor, vuelva a intentarlo en <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  errorLimit10PageBtn: "Volver a la pantalla inicial",

  // Error Reg Limit
  errorRegLimitTitle: "Inténtelo más tarde",
  errorRegLimitText:
    "La cantidad de registros con un número es limitada. Por favor, intente registrarse dentro de 4 horas",
  errorRegLimitBtn: "Volver a la pantalla inicial",

  // Error unforeseen
  errorUnforeseenTitle: "Se ha producido un error",
  errorUnforeseenText: "Vuelva a iniciar sesión",
  errorUnforeseenBtn: "Entendido",

  // Bot dialog
  botDialogTitle: "Se ha producido un error",
  botDialogText: "Vuelva a iniciar sesión",
  botDialogBtn: "Entendido",

  // Email for login not found
  emailNotFoundTitile:
    "Lamentablemente, no se encontró perfil para este correo electrónico",
  emailNotFoundText: "Necesitas autenticarte a través del número de teléfono",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "Esta opción solo está disponible para representantes de personas jurídicas",
  loginByEmailDeclinedText:
    "Necesitas autenticarte a través del número de teléfono",
};

export default es;
