const en = {
  accept: "OK",
  next: "Continue",
  back: "Back",
  skip: "Skip",
  language: "Language",
  loading: "Loading...",

  // Errors
  wrongRepeat: "Passwords do not match",
  requireField: "Required field",
  errorFioField: "Please, verify that the data entered is correct",
  pinMust4: "Input 4 or more characters",
  pinMust6: "Input 6 or more characters",
  must4: "Input 4 numerals",
  must6: "Input 6 numerals",
  must9: "Input 9 numerals",
  protectionPhoneMainError: "Coincides with the main number",
  mustBe: "Input ",
  mustBeEnd: " numerals",
  unknownOperator: "Mobile numbers only",
  contactRepeats: "The new contact matches an already existing one",
  errorOldBrowserTitle: "Please update your browser",
  errorOldBrowserText:
    "Your current browser version is outdated. To continue, please update your browser",
  enterEmailError: "Incorrect email",
  errorPageTitle502: "The service is temporarily unavailable",
  errorPageText502: "Please try to connect later",
  latNameError: "Please use only Latin characters",
  wrongOtp: "The code you entered is incorrect. {attempts} attempts remaining",
  wrongPassword:
    "The password you entered is incorrect. {attempts} attempts remaining",

  // Phone input
  inputCountry: "Country",
  inputCountryName: "Name of the country",
  inputFound: "Search results",
  inputNotFound: "No results matching your request <span>”{text}”</span>",

  // Email input
  emailInputLabel: "Email",

  // Start
  startTitle: "Welcome to Nova Post!",
  enterTitle: "Enter your phone number",
  enterEmailTitle: "Enter your email address",
  enterAcceptBtn: "Send code",
  enterText: "You can log in or create an account if you're new to Nova Post.",
  enterTextEmail: "Enter your email to log in",
  enterOrRegistration: "Log in",
  shouldRegistration:
    "Log in to access advanced settings, order additional services, and make online payments",
  enterOr: "or",
  enterPopupBtnEmail: "Log in with email",
  enterPopupBtnPhone: "Log in with phone number",

  // Social
  socialAppleBtn: "Continue with Apple",
  socialGoogleBtn: "Continue with Google",
  socialDeclinePopupTitle: "Unable to authorize",
  socialDeclinePopupText:
    "Unfortunately, there is no such account. Please authenticate using your phone number, and then add your Google or Apple account in profile settings.",
  socialDeclinePopupBtn: "Understood",

  // Enter Otp
  enterOtpTitle: "Enter the code from SMS",
  enterOtpTitleEmail: "Enter the code from email",
  enterOtpTextPhone:
    "Confirmation code has been sent to number <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span>",
  enterOtpTextEmail:
    "Confirmation code has been sent to email <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span>",
  enterOtpSocialTitle: "Enter your phone number to sign in with {process}",
  enterOtpSocialText:
    "The phone number is required for the first sign-in with {process} to display information about your parcels. For future sign-ins, you won't need to enter your phone number.",
  enterOtpCalling: "Dialling your number",
  resendOtp: "Resend code",
  resendOtpByCall: "I prefer a call",
  resendOtpAfter: "Send again in 0:{time}",
  enterOtp502Title:
    "Unfortunately, there was no response on the dialled number",
  enterOtp502Text: "There may be a connection issue. Please try again",

  // Enter password
  enterPasswordTitle: "Enter the password",
  enterPasswordText: "You created this password when signing up",
  enterPasswordLabel: "Password",
  enterPasswordForgot: "Forgot your password?",
  passwordPopupTitle: "Incorrect password",
  passwordPopupText:
    "You or somebody else entered an incorrect password several times. We have blocked your account for security purposes",
  passwordPopupBtn1: "Return to home screen",

  // Create password
  createPasswordTitle: "Create a new password",
  createPasswordText:
    "For phone number <br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Password",
  createPasswordInput1Hint: "Input 6 or more characters",
  createPasswordInput2Label: "Repeat the password",

  // Registration
  registrationTitle: "Create an account",
  registrationText: "To sign up, enter your phone number and create a password",
  registrationInput1Label: "Password",
  registrationInput1Hint: "Should contain at least 6 characters",
  registrationInput2Label: "Repeat the password",
  registrationPolicy: `By proceeding I confirm that I have read and understood {0} and consent to the processing of my personal data`,
  registrationPolicyLink: `the Privacy Policy`,
  registrationPolicy1: `By proceeding I confirm that I have read and understood`,
  registrationPolicy2: `the Privacy Policy`,
  registrationPolicy3: `and consent to the processing of my personal data`,
  registration: "",

  // Full name
  fullNameTitle: "Create a profile",
  fullNameText: "For receiving and sending parcels.",
  fullNameBtn: "Register",
  fullNameFirst: "First name",
  fullNameLast: "Last name",
  fullNameMiddle: "Middle name",
  fullNameCheckbox: "No middle name in the documents",

  // Create recovery
  createRecoveryTitle: "Protect your account",
  createRecoveryText:
    "Provide one of the additional contacts (your email or an additional phone number) to be able to restore your account quicker when needed",
  createRecoveryHint:
    "The phone number cannot match the one you provided during when signing up",

  // Choose recovery
  chooseRecoveryTitle: "Password recovery",
  chooseRecoveryText:
    "Please choose a contact and wait for the confirmation code",
  chooseRecoveryGreyBtn: "I cannot receive my code",

  // Profile blocked
  profileBlockedTitle: "Access to the app is temporarily restricted",
  profileBlockedText:
    "You have used all attempts to enter the code. Please try again in 10 minutes.",
  profileBlockedTimer:
    "Try again in: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "Your account will be unlocked in <span class='no-wrap text-black text-weight-medium'>{minutes} minutes</span>",
  profileBlockedBtn: "OK",

  // Recovery not found
  recoveryNotFoundTitle: "Please create a new account",
  recoveryNotFoundText1:
    "You have not provided any alternative contacts, so we cannot restore the access to your account with phone number <span class='no-wrap text-black text-weight-medium'>+{phone}</span>",
  recoveryNotFoundText2:
    "Please sign up again with the same number. The information about your parcels will be restored, but we are unable to save the points you have accumulated",
  recoveryNotFoundBtn: "Sign up",
  recoveryNotNowBtn: "Not now",

  recoveryNotFoundText1Another:
    "You cannot access your contacts, so we cannot restore the access to your account with phone number <span class='no-wrap text-black text-weight-medium'>+{phone}</span>",

  // Password changed
  passwordChangedTitle: "The password has been changed",
  passwordChangedText1:
    "Please use this password only at novapost.com and in the application.",
  passwordChangedText2:
    "Please do not tell anyone your password. Our employees will never ask for it",
  passwordChangedBtn: "OK",

  // Error limit 10
  errorLimit10PageTitle: "Your number is temporarily blocked",
  errorLimit10PageTextWithNumber:
    "We were unable to confirm your number by code or your messages were not delivered dues to an unstable connection.",
  errorLimit10PageTimer:
    "Please try again in <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  errorLimit10PageBtn: "Return to home screen",

  // Error Reg Limit
  errorRegLimitTitle: "Try again later",
  errorRegLimitText:
    "Sign up attempts under the same phone number are limited. Please try to sign up again in 4 hours",
  errorRegLimitBtn: "Return to home screen",

  // Error unforeseen
  errorUnforeseenTitle: "An error occurred",
  errorUnforeseenText: "Please try again to enter",
  errorUnforeseenBtn: "OK",

  // Bot dialog
  botDialogTitle: "An error occurred",
  botDialogText: "Please try again to log in",
  botDialogBtn: "OK",

  // Email for login not found
  emailNotFoundTitile: "Unfortunately, no profile found for this email",
  emailNotFoundText: "You need to authenticate via phone number",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "This option is available only for legal entity representatives",
  loginByEmailDeclinedText: "You need to authenticate via phone number",
};

export default en;
