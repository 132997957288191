const cz = {
  accept: "Rozumím",
  next: "Pokračovat",
  back: "Zpět",
  skip: "Přeskočit",
  language: "Jazyk",
  loading: "Načítání...",

  // Errors
  wrongRepeat: "Hesla se neshodují",
  requireField: "Toto pole je povinné",
  errorFioField: "Zkontrolujte správnost zadaných údajů",
  pinMust4: "Musí obsahovat minimálně 4 znaky",
  pinMust6: "Musí obsahovat minimálně 6 znaků",
  must4: "Musí obsahovat 4 číslice",
  must6: "Musí obsahovat 6 číslic",
  must9: "Musí obsahovat 9 číslic",
  protectionPhoneMainError: "Shoduje se s hlavním číslem",
  mustBe: "Musí obsahovat",
  mustBeEnd: "číslic",
  unknownOperator: "Pouze mobilní telefonní čísla",
  contactRepeats: "Nový kontakt odpovídá dříve zadanému kontaktu",
  errorOldBrowserTitle: "Aktualizujte svůj prohlížeč",
  errorOldBrowserText:
    "V současné době používáte starší verzi prohlížeče. Chcete-li pokračovat, aktualizujte svůj prohlížeč",
  enterEmailError: "Chybná e-mailová adresa",
  errorPageTitle502: "Služba je dočasně nedostupná",
  errorPageText502: "Zkuste to prosím znovu později.",
  latNameError: "Vyplňte prosím latinkou",
  wrongOtp: "Zadali jste nesprávný kód. Zbývá {attempts} pokus(y)",
  wrongPassword: "Zadali jste nesprávné heslo. Zbývá {attempts} pokus(y)",

  // Phone input
  inputCountry: "Země",
  inputCountryName: "Název země",
  inputFound: "Výsledky vyhledávání",
  inputNotFound: "Pro zadaný dotaz jsme nic nenašli <span>”{text}”</span>",

  // Email input
  emailInputLabel: "E-mail",

  // Start
  startTitle: "Vítejte v Nova Posta!",
  enterTitle: "Zadejte telefonní číslo",
  enterEmailTitle: "Zadejte e-mailovou adresu",
  enterAcceptBtn: "Odeslat kód",
  enterText:
    "Můžete se přihlásit nebo vytvořit účet, pokud jste v Nova Post poprvé.",
  enterTextEmail: "Pro přihlášení zadejte svůj e-mail",
  enterOrRegistration: "Přihlášení",
  shouldRegistration:
    "Přihlaste se a získejte přístup k rozšířeným nastavením, objednejte si další služby a plaťte za ně online.",
  enterOr: "nebo",
  enterPopupBtnEmail: "Přihlásit se e-mailem",
  enterPopupBtnPhone: "Přihlásit se telefonním číslem",

  // Social
  socialAppleBtn: "Pokračovat s Apple",
  socialGoogleBtn: "Pokračovat s Google",
  socialDeclinePopupTitle: "Ani možnost autorizace",
  socialDeclinePopupText:
    "Bohužel, tento účet neexistuje. Přihlaste se prosím pomocí svého telefonního čísla a poté přidejte váš účet Google nebo Apple v nastavení profilu.",
  socialDeclinePopupBtn: "Rozumím",

  // Enter Otp
  enterOtpTitle: "Zadejte kód z SMS",
  enterOtpTitleEmail: "Zadejte kód z e-mailu",
  enterOtpTextPhone:
    "Potvrzovací kód jsme odeslali na číslo <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span>",
  enterOtpTextEmail:
    "Potvrzovací kód jsme odeslali na email <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span>",
  enterOtpSocialTitle: "Uveďte telefonní číslo pro přihlášení pomocí {process}",
  enterOtpSocialText:
    "Telefonní číslo je vyžadováno pro první přihlášení pomocí {process}, aby se zobrazily informace o vašich zásilkách. Při dalším přihlášení již nebude nutné zadávat telefonní číslo.",
  enterOtpCalling: "Zavoláme Vám",
  resendOtp: "Odeslat kód znovu",
  resendOtpByCall: "Raději zavolejte",
  resendOtpAfter: "Odeslat znovu po 0:{time}",
  enterOtp502Title: "Vámi zadané číslo je bohužel nedostupné",
  enterOtp502Text:
    "To může být způsobeno špatným připojením. Zkuste to prosím znovu",

  // Enter password
  enterPasswordTitle: "Zadejte heslo",
  enterPasswordText: "Toto heslo jste zadali při registraci",
  enterPasswordLabel: "Heslo",
  enterPasswordForgot: "Zapomněli jste heslo?",
  passwordPopupTitle: "Nesprávné heslo",
  passwordPopupText:
    "Vy nebo někdo jiný několikrát zadal nesprávné heslo. Váš profil je z bezpečnostních důvodů dočasně zablokován.",
  passwordPopupBtn1: "Zpět na domovskou stránku",

  // Create password
  createPasswordTitle: "Vytvořit nové heslo",
  createPasswordText:
    "Pro číslo <br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Heslo",
  createPasswordInput1Hint: "Musí obsahovat minimálně 6 znaků",
  createPasswordInput2Label: "Zopakujte heslo",

  // Registration
  registrationTitle: "Vytvořit profil",
  registrationText:
    "Chcete-li se zaregistrovat, zadejte své telefonní číslo a vytvořte si heslo",
  registrationInput1Label: "Heslo",
  registrationInput1Hint: "Musí obsahovat minimálně 6 znaků",
  registrationInput2Label: "Zopakujte heslo",
  registrationPolicy: `Potvrzuji, že jsem si přečetl(a) {0} a souhlasím se zpracováním osobních údajů`,
  registrationPolicyLink: `Zásady ochrany osobních údajů`,
  registrationPolicy1: `Potvrzuji, že jsem si přečetl(a)`,
  registrationPolicy2: `Zásady ochrany osobních údajů`,
  registrationPolicy3: `a souhlasím se zpracováním osobních údajů`,
  registration: "",

  // Full name
  fullNameTitle: "Vytvořte profil",
  fullNameText: "Pro přijímání a odesílání zásilek.",
  fullNameBtn: "Registrovat se",
  fullNameFirst: "Jméno",
  fullNameLast: "Příjmení",
  fullNameMiddle: "Druhé jméno",
  fullNameCheckbox: "Nemám druhé jméno",

  // Create recovery
  createRecoveryTitle: "Chraňte svůj profil",
  createRecoveryText:
    "Přidejte jednou z dalších kontaktů (Váš e-mail nebo další číslo), abyste v případě potřeby mohli svůj profil rychleji obnovili.",
  createRecoveryHint:
    "Telefonní číslo se nesmí shodovat s číslem zadaným při registraci",

  // Choose recovery
  chooseRecoveryTitle: "Obnovení hesla",
  chooseRecoveryText: "Vyberte kontakt a počkejte na potvrzovací kód",
  chooseRecoveryGreyBtn: "Nemůžu získat kód",

  // Profile blocked

  profileBlockedTitle: "Přístup k aplikaci dočasně omezen",
  profileBlockedText:
    "Vyčerpali jste všechny pokusy o zadání kódu. Zkuste to znovu za 10 minut.",
  profileBlockedTimer:
    "Zkuste to znovu za: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "Profil bude odblokován za <span class='no-wrap text-black text-weight-medium'>{minutes} min</span>",
  profileBlockedBtn: "Rozumím",

  // Recovery not found
  recoveryNotFoundTitle: "Zaregistrujte nový profil",
  recoveryNotFoundText1:
    "Nezadali jste další kontakty, takže Vám nemůžeme obnovit přístup k profilu podle čísla <span class='no-wrap text-black text-weight-medium'>+{phone}</span>",
  recoveryNotFoundText2:
    "Zaregistrujte se prosím znovu pod stejným číslem. Informace o vašich zásilkách zůstanou zachovány, ale nasbírané body nebudeme moci uložit",
  recoveryNotFoundBtn: "Zaregistrovat se",
  recoveryNotNowBtn: "Teď ne",

  recoveryNotFoundText1Another:
    "Nemáte přístup ke svým kontaktům, takže Vám nemůžeme obnovit přístup k profilu podle čísla <span class='no-wrap text-black text-weight-medium'>+{phone}</span>.",

  // Password changed
  passwordChangedTitle: "Heslo bylo změněno",
  passwordChangedText1:
    "Heslo používejte pouze na webu novapost.com a v aplikaci.",
  passwordChangedText2:
    "Nikomu nesdělujte své heslo. Naši zaměstnanci se ho nikdy neptají",
  passwordChangedBtn: "Rozumím",

  // Error limit 10
  errorLimit10PageTitle: "Vaše číslo je dočasně zablokováno",
  errorLimit10PageTextWithNumber:
    "Vaše číslo se nám nepodařilo ověřit podle kódu nebo vaše zprávy nebyly doručeny z důvodu nestabilního připojení.",
  errorLimit10PageTimer:
    "Zkuste to prosím znovu za <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  errorLimit10PageBtn: "Zpět na domovskou stránku",

  // Error Reg Limit
  errorRegLimitTitle: "Zkuste později",
  errorRegLimitText:
    "Počet registrací na jedno číslo je omezen. Zkuste se zaregistrovat za 4 hodiny",
  errorRegLimitBtn: "Zpět na domovskou stránku",

  // Error unforeseen
  errorUnforeseenTitle: "Došlo k chybě",
  errorUnforeseenText: "Zkuste se přihlásit znovu",
  errorUnforeseenBtn: "Rozumím",

  // Bot dialog
  botDialogTitle: "Došlo k chybě",
  botDialogText: "Zkuste se prosím znovu přihlásit",
  botDialogBtn: "Rozumím",

  // Email for login not found
  emailNotFoundTitile: "Bohužel nenalezen profil pro tento e-mail",
  emailNotFoundText: "Je nutné se autorizovat pomocí telefonního čísla",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "Tato možnost je k dispozici pouze pro zástupce právnických osob",
  loginByEmailDeclinedText: "Je nutné se autorizovat pomocí telefonního čísla",
};

export default cz;
