import axios from "axios";
import * as Sentry from "@sentry/browser";

// const host = location.hostname.includes('localhost')? '/': 'https://id.novaposhta.ua/profile';
const host = "/";
const hostFuzzy = "https://fuzzyname.npu.np.work/api/v1/names";
const csrfToken = document.getElementsByName("authenticity.token")[0]
  ? document.getElementsByName("authenticity.token")[0].value
  : "";

const axiosInstance = axios.create({
  baseURL: host,
  method: "post",
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
    "X-CSRF-Token": csrfToken,
    // 'Accept-Language': store.state.profile.language,
  },
});

export const fuzzyInstance = axios.create({
  baseURL: hostFuzzy,
  method: "post",
  timeout: 10000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Sentry.addBreadcrumb({
    //   category: "api",
    //   message: "Request",
    //   method: config.method,
    //   url: config.url,
    //   data: config.data,
    //   headers: config.headers,
    //   level: "info",
    // });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (config) => {
    // Sentry.addBreadcrumb({
    //   category: "api",
    //   message: "Response",
    //   data: config.response.data,
    //   headers: config.response.headers,
    //   status: config.response.status,
    //   level: "info",
    // });
    if (config.data.error) {
      //////////
      const error = new Error(config.data.error);
      error.status = config.data.errorCode;
      error.redirect = config.data.redirect_to;
      error.attempts = config.data.attempts;

      if (config.data.errorCode !== 401) {
        Sentry.addBreadcrumb({
          category: "log",
          level: "error",
          message: config.data.error,
          data: {
            message: config.data.error,
            redirect_to: config.data.redirect_to.split("?")[0],
            status: config.data.errorCode,
          },
        });
        Sentry.captureException(error, {
          level: "error",
        });
      }

      return Promise.reject(error);
      //////////
    }
    return config;
  },
  (error) => {
    // console.log("interceptors catch error", error.toJSON());
    Sentry.addBreadcrumb({
      category: "log",
      level: "error",
      message: "Response",
      data: {
        message: error.message || "Empty error",
        redirect_to: error.redirect.split("?")[0],
        status: error.status,
      },
    });
    Sentry.captureException(error, {
      level: "error",
    });

    return Promise.reject(error.toJSON());
  }
);

export default axiosInstance;
